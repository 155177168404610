import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RiShieldStarFill } from "react-icons/ri";
import { BasicModal } from "../BasicModal";
import { LCARecommendations } from "../LCARecommendations";
import { useTranslation } from "react-i18next";

export default function InitialStepSupplier() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showLCARecommendations, setShowLCARecommendations] = useState(false);

	return (
		<>
			<Box
				sx={{
					width: "100%",
					textAlign: "center",
					padding: 4,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					border: "1px solid #E3E3E3",
					borderRadius: "20px",
				}}
			>
				<Typography
					variant="h5"
					sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
				>
					{t("initialStep.worldChangingProducts")}
					<br />
					{t("initialStep.exposureDeserve")}
				</Typography>
				<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
					{
						"Start by creating surveys for your suppliers to get a better knowledge of what it is involved on building and delivering your products and services."
					}
				</Typography>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						marginBottom: 2,
						width: 180,
						height: 140,
						border: "1px solid #D6E9FF",
						borderRadius: 4,
						padding: 2,
						textAlign: "left",
					}}
				>
					<RiShieldStarFill
						style={{
							fontSize: 20,
							color: "#00A889",
							marginBottom: 2,
						}}
					/>
					<Typography variant="h6" sx={{ fontSize: 18 }}>
						{"New survey"}
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						gap: 2,
					}}
				>
					<Button
						variant="contained"
						sx={{
							minWidth: 180,
						}}
						onClick={() => {
							navigate("/form");
						}}
					>
						{"Create a survey"}
					</Button>
				</Box>
			</Box>
			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setShowLCARecommendations(false);
				}}
				children={
					<LCARecommendations
						setLCARecommendations={setShowLCARecommendations}
					/>
				}
				hideClose
			/>
		</>
	);
}
