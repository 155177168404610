import {
	Typography,
	Box,
	Button,
	CircularProgress,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
} from "@mui/material";
import { PropTypes } from "./CertificationAgree.types";
import { useTranslation } from "react-i18next";

export function CertificationAgree({
	text,
	button1,
	button1Callback,
	button2,
	button2Callback,
	isLoading,
	certification,
	historicalCertification,
	subscriptions,
	annualFeeToPay,
	lcaFeeToPay,
	lcaFeeToPayMultiplier,
	revisedLCAFeeToPay,
	revisedLCAFeeToPayMultiplier,
	rejectionsLCAFeeToPay,
	balance,
	offsets,
	offsetsPercentage,
	totalOffsets,
}: PropTypes) {
	const { t } = useTranslation();

	return (
		<Box sx={{ width: "100%" }}>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				{text}
			</Typography>

			{certification && (
				<Box
					sx={{
						backgroundColor: "#FAFDFF",
						padding: 4,
						minHeight: "256px",
						display: "flex",
						flexDirection: "column",
						borderTop: "1px solid #D7EBFF",
						borderBottom: "1px solid #D7EBFF",
					}}
				>
					<Box
						sx={{
							minHeight: "256px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{!certification?.is_update_lca && (
							<>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										flexDirection: {
											xs: "column",
											sm: "row",
										},
									}}
								>
									<Typography>
										{t("certificationAgree.priceOfOffsets")}:
									</Typography>

									<Typography>
										{Intl.NumberFormat("en", {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
											style: "currency",
											currency: "usd",
										}).format(12.5)}{" "}
										x{" "}
										{Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
											minimumFractionDigits: 0,
										}).format(offsets ?? 0)}{" "}
										={" "}
										{Intl.NumberFormat("en", {
											maximumFractionDigits: 2,
											minimumFractionDigits: 2,
											style: "currency",
											currency: "usd",
										}).format((offsets ?? 0) * 12.5)}
									</Typography>
								</Box>
								{(offsetsPercentage ?? 0) > 0 && (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											flexDirection: {
												xs: "column",
												sm: "row",
											},
										}}
									>
										<Typography>
											{t("certificationAgree.offsetsPercentage")}:
										</Typography>

										<Typography>
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format(12.5)}{" "}
											x{" "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(offsetsPercentage ?? 0)}{" "}
											={" "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format((offsetsPercentage ?? 0) * 12.5)}
										</Typography>
									</Box>
								)}
							</>
						)}

						{(totalOffsets ?? 0) * 12.5 +
							(annualFeeToPay ?? 0) +
							(lcaFeeToPay ?? 0) +
							(revisedLCAFeeToPay ?? 0) /*-
							(balance && (balance ?? 0) > 0 ? balance : 0)*/ <
							5000 &&
							(!certification.company.first_time_fee_paid ||
								(certification.company.first_time_fee_paid &&
									new Date(
										certification.company.first_time_fee_paid
									).getTime() <
										new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) && (
								<>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: 1,
										}}
									>
										<Typography
											sx={{
												maxWidth: 320,
												color: "#EB4B4B",
											}}
										>
											{t("certificationAgree.priceOfOffsetsDesc")}
										</Typography>
										<Typography
											sx={{
												color: "#EB4B4B",
												textAlign: "right",
											}}
										>
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format(12.5)}{" "}
											x{" "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(
												(5000 -
													((totalOffsets ?? 0) * 12.5 +
														(annualFeeToPay ?? 0) +
														(lcaFeeToPay ?? 0) +
														(revisedLCAFeeToPay ?? 0) /*+
														(rejectionsLCAFeeToPay ?? 0)*/ +
														0)) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/ /
													12.5
											)}
											{" = "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format(
												5000 -
													((totalOffsets ?? 0) * 12.5 +
														(annualFeeToPay ?? 0) +
														(lcaFeeToPay ?? 0) +
														(revisedLCAFeeToPay ?? 0) +
														/*(rejectionsLCAFeeToPay ?? 0)*/ +0) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/
											)}
										</Typography>
									</Box>
									<Typography
										sx={{
											fontStyle: "italic",
											marginBottom: 2,
										}}
									>
										<small>{t("certificationAgree.additionalOffsets")}</small>
									</Typography>
								</>
							)}

						{(annualFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "ANNUAL_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(annualFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}

						{(lcaFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "LCA_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(
										(lcaFeeToPay ?? 0) / (lcaFeeToPayMultiplier ?? 1)
									)}{" "}
									x{" "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 0,
										minimumFractionDigits: 0,
									}).format(lcaFeeToPayMultiplier ?? 0)}
									{" = "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(lcaFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{(revisedLCAFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "UPDATED_LCA_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(
										(revisedLCAFeeToPay ?? 0) /
											(revisedLCAFeeToPayMultiplier ?? 1)
									)}{" "}
									x{" "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 0,
										minimumFractionDigits: 0,
									}).format(revisedLCAFeeToPayMultiplier ?? 0)}
									{" = "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(revisedLCAFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{(rejectionsLCAFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{t("certificationAgree.lcaRejectionFee")}
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(rejectionsLCAFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{false && (balance ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{t("certificationAgree.currentBalance")}
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(0 /*balance ? -balance : 0*/)}
								</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: "flex",
								marginTop: 4,
								padding: 0,
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontWeight: 500 }}>
								{t("certificationAgree.totalPrice")}
							</Typography>
							<Typography sx={{ fontWeight: 500, color: "#34918E" }}>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
									style: "currency",
									currency: "usd",
								}).format(
									(!certification.company.first_time_fee_paid ||
										(certification.company.first_time_fee_paid &&
											new Date(
												certification.company.first_time_fee_paid
											).getTime() <
												new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) &&
										(totalOffsets ?? 0) *
											(certification?.is_update_lca ? 0 : 1) *
											12.5 +
											(annualFeeToPay ?? 0) +
											(lcaFeeToPay ?? 0) +
											(revisedLCAFeeToPay ?? 0) /*-
										(balance && (balance ?? 0) > 0 ? balance : 0)*/ <
											5000
										? 5000 + (rejectionsLCAFeeToPay ?? 0)
										: (totalOffsets ?? 0) *
												(certification?.is_update_lca ? 0 : 1) *
												12.5 +
												(annualFeeToPay ?? 0) +
												(lcaFeeToPay ?? 0) +
												(revisedLCAFeeToPay ?? 0) +
												(rejectionsLCAFeeToPay ?? 0) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/
								)}
							</Typography>
						</Box>
						{(offsetsPercentage ?? 0) > 0 && (
							<Typography
								sx={{
									fontSize: 12,
									fontStyle: "italic",
									opacity: 0.5,
									marginTop: 1,
								}}
							>
								{t("certificationAgree.offsetsPercentageDesc")}
							</Typography>
						)}
					</Box>
				</Box>
			)}
			{historicalCertification && (
				<Box
					sx={{
						padding: 4,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>{t("certificationAgree.productAsin")}</TableCell>
									<TableCell align="right">
										{t("certificationAgree.totalHistoricalSales")}
									</TableCell>
									<TableCell align="right">
										{" "}
										{t("certificationAgree.totalEstimatedSales")}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{historicalCertification.estimations
									.filter(
										(estimation) =>
											!certification?.is_add_products ||
											(certification?.is_add_products &&
												estimation.is_add_products)
									)
									.map((estimation) => (
										<TableRow
											key={estimation.product.name + estimation.product.asin}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{estimation.product.name +
													" - " +
													estimation.product.asin}
											</TableCell>
											<TableCell align="right">
												{Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
													minimumFractionDigits: 0,
												}).format(
													estimation.product.sales?.reduce(
														(prev, current) => prev + current.amount,
														0
													) ?? 0
												)}
											</TableCell>
											<TableCell align="right">
												{Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
													minimumFractionDigits: 0,
												}).format(estimation.amount ?? 0)}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						marginRight: 2,
					}}
					onClick={button1Callback}
					disabled={isLoading}
				>
					{button1}
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						minHeight: 34,
					}}
					disabled={isLoading}
					onClick={button2Callback}
				>
					{isLoading ? (
						<CircularProgress size={12} style={{ color: "white" }} />
					) : (
						button2
					)}
				</Button>
			</Box>
		</Box>
	);
}
