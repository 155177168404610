import { useContext, useState, useEffect } from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	TextField,
	Typography,
	Tabs,
	Tab,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
	BasicModal,
	BulkProductsTable,
	CertificationAgree,
	CertificationConfirmation,
} from "..";
import {
	ICertification,
	ICertificationSteps,
	IEstimation,
	IProduct,
	IStep,
	ILCA,
} from "../../models";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	certification as certificationActions,
	certificationProcessStep as certificationProcessStepActions,
	estimation as estimationsActions,
	sale as saleActions,
	strings as stringActions,
	product as productActions,
	lca as lcaActions,
	productLCAVersionEmission as productLCAVersionEmissionActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { ConfirmationDataPropTypes } from "../CertificationConfirmation/CertificationConfirmation.types";
import { PurchaseOffsets } from "../PurchaseOffsets";
import { NumericFormat } from "react-number-format";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfettiExplosion from "react-confetti-explosion";
import { motion } from "framer-motion";
import { UploadContracts } from "../UploadContracts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ProductSummarys } from "../ProductSummarys";
import { ISubscription } from "../../models/subscription-model";
import LCACollapsible from "../LCACollapsible/LCACollapsible";
import { PropTypes } from "./ClimecoCertificationProcesss.types";
import CertificationComplete from "../CertificationComplete/CertificationComplete";
import LoadingBar from "../LoadingBar/LoadingBar";
import CertificationReview from "../CertificationReview/CertificationReview";
import { useTranslation } from "react-i18next";
import { UploadProductsCSV } from "../UploadProductsCSV";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
		color: "#888",
	},
});

let loadingDocuSignResultInterval: NodeJS.Timer | null = null;
let loadingOffsetsInterval: NodeJS.Timer | null = null;

export function ClimecoCertificationProcesss({
	id: auxId,
	certification,
	setCertification,
	createProducts,
	setCreateProducts,
	setNextButtonEnabled,
	setParentCurrentStep,
}: PropTypes) {
	const classes = useStyles();
	const { id: paramsId } = useParams();
	const id = auxId || paramsId;
	const location = useLocation();
	const [refetchedCertification, setRefetchedCertification] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentProducts, setCurrentProducts] = useState(
		location.state?.products
			? (location.state.products as Array<IProduct>)
			: Array<IProduct>()
	);
	const [currentEstimations] = useState(Array<IEstimation>());
	const [originalEstimations, setOriginalEstimations] = useState(
		Array<IEstimation>()
	);
	const { t } = useTranslation();
	const [products, setAuxProducts] = useState<Array<IProduct>>([]);
	const setProducts = (newProducts: Array<IProduct>) => {
		setAuxProducts(newProducts);
		setCurrentProducts(newProducts);

		if (
			newProducts.find(
				(r) =>
					r.asin &&
					newProducts.find(
						(r2) => r.id !== r2.id && r2.asin && r2.asin === r.asin
					)
			)
				? true
				: false
		) {
			enqueueSnackbar(t("climecoCertificationProcess.asinError"), {
				variant: "error",
			});
		}
	};
	const [description, setDescription] = useState<string | undefined>(undefined);
	/*const [certification, setCertification] = useState<ICertification | null>(
		null
	);*/
	const [reloadCertification, setReloadCertification] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationData, setConfirmationData] =
		useState<ConfirmationDataPropTypes>({
			title: "",
			description: "",
			textfield: null,
			callback: () => {},
		});
	const [loadingDocuSignResult, setLoadingDocuSignResult] = useState(false);
	const [loadingOffsetsResult, setLoadingOffsetsResult] = useState(false);
	const { user } = useContext(UserContext);
	const [showMarkItAsPaidModal, setShowMarkItAsPaidModal] = useState(false);
	const [balance, setBalance] = useState<number>(0);
	const [showAgreeModal, setShowAgreeModal] = useState(false);
	const [showNewLCAVersionModal, setShowNewLCAVersionModal] = useState(false);
	const [manualOffsets, setManualOffsets] = useState<number | undefined>(
		undefined
	);
	const [manualOffsetsPrice, setManualOffsetsPrice] = useState<number | string>(
		""
	);
	const [messagesIndex, setMessagesIndex] = useState(0);
	const [showPurchaseOffsets, setShowPurchaseOffsets] = useState(false);
	const [docuSignLoadingMessages, setDocuSignLoadingMessages] = useState([
		t("climecoCertificationProcess.waitingDocuSign"),
		t("climecoCertificationProcess.finalizingContracts"),
		t("climecoCertificationProcess.fetchingOffsets"),
		t("climecoCertificationProcess.configuringOffers"),
		t("climecoCertificationProcess.almostFinishing"),
	]);
	const [offsetsLoadingMessages, setOffsetsLoadingMessages] = useState([
		t("climecoCertificationProcess.creatingInvoice"),
		t("climecoCertificationProcess.reservingOffsets"),
		t("climecoCertificationProcess.checkingDetails"),
		t("climecoCertificationProcess.finalizingCertification"),
		t("climecoCertificationProcess.aboutToFinish"),
	]);
	const [subscriptions, setSubscriptions] = useState<Array<ISubscription>>(
		new Array<ISubscription>()
	);
	const [LCA, setLCA] = useState<ILCA | null>(null);
	const [selectedLCA] = useState(0);

	const [annualFeeToPay, setAnnualFeeToPay] = useState<number>(0);
	const [lcaFeeToPay, setLcaFeeToPay] = useState<number>(-1);
	const [lcaFeeToPayMultiplier, setLcaFeeToPayMultiplier] = useState(0);
	const [revisedLCAFeeToPay, setRevisedLCAFeeToPay] = useState<number>(-1);
	const [revisedLCAFeeToPayMultiplier, setRevisedLCAFeeToPayMultiplier] =
		useState<number>(0);
	const [rejectionsLCAFeeToPay, setRejectionsLCAFeeToPay] =
		useState<number>(-1);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));

	const [showTitleModal, setShowTitleModal] = useState(false);
	const [showAmazonModal, setShowAmazonModal] = useState(false);

	const [isLoadingProducts, setIsLoadingProducts] = useState(false);
	const [progress, setProgress] = useState(0);
	const [csvLoading, setCsvLoading] = useState(false);
	const [isDocusignLinkGenerated, setIsDocusignLinkGenerated] = useState(false);
	const [isLoadingAdminApproval, setIsLoadingAdminApproval] = useState(false);
	const [showCSVUploadModal, setShowCSVUploadModal] = useState(false);
	const [handleEvent, setHandleEvent] =
		useState<React.ChangeEvent<HTMLInputElement> | null>(null);

	const [tabValue, setTabValue] = useState<number>(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};
	const [openAdminInfo, setOpenAdminInfo] = useState(true);

	const [expandedPanel, setExpandedPanel] = useState<string | false>("panel-0");

	const handleExpand = (panel: string) => {
		setExpandedPanel(expandedPanel === panel ? false : panel);
	};

	useQuery("strings", () => stringActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			if (res.results.length > 0) {
				setDocuSignLoadingMessages([
					(res.results[0] as any)["docusign_loading_1"],
					(res.results[0] as any)["docusign_loading_2"],
					(res.results[0] as any)["docusign_loading_3"],
					(res.results[0] as any)["docusign_loading_4"],
					(res.results[0] as any)["docusign_loading_5"],
				]);
				setOffsetsLoadingMessages([
					(res.results[0] as any)["offsets_loading_1"],
					(res.results[0] as any)["offsets_loading_2"],
					(res.results[0] as any)["offsets_loading_3"],
					(res.results[0] as any)["offsets_loading_4"],
					(res.results[0] as any)["offsets_loading_5"],
				]);
			}
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	const { refetch: refetchLink, isLoading: isLoadingRefecthLink } = useQuery(
		"generate-link",
		() =>
			certificationActions.generateLink(
				true,
				parseInt(id!),
				user?.is_superuser ? certification?.company.id : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
				searchParams.delete("event");
				setSearchParams(searchParams);
				setIsDocusignLinkGenerated(true);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() => saleActions.getBalance([], certification?.company.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				/*const num =
					Math.sign((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0)) *
						Math.ceil(
							Math.abs((res?.estimated_amount ?? 0) - (res?.sales_amount ?? 0))
						) +
					Math.ceil(res?.offsets_amount ?? 0);
				setBalance(
					Math.ceil(num) === -0 ? 0 : Math.sign(num) * Math.ceil(Math.abs(num))
				);*/
				setBalance(
					(res?.purchased_offsets ?? 0) -
						(res?.sales_amount ?? 0) +
						(res?.offsets_amount ?? 0) /*-
						(res?.estimated_amount ?? 0)*/
				);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (loadingDocuSignResult) {
			setMessagesIndex(0);
			loadingDocuSignResultInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === docuSignLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingDocuSignResultInterval) {
			clearInterval(loadingDocuSignResultInterval);
		}

		return () => {
			if (loadingDocuSignResultInterval) {
				clearInterval(loadingDocuSignResultInterval);
			}
		};
	}, [loadingDocuSignResult, docuSignLoadingMessages.length]);

	useEffect(() => {
		if (loadingOffsetsResult) {
			setMessagesIndex(0);
			loadingOffsetsInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === offsetsLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingOffsetsInterval) {
			clearInterval(loadingOffsetsInterval);
		}

		return () => {
			if (loadingOffsetsInterval) {
				clearInterval(loadingOffsetsInterval);
			}
		};
	}, [loadingOffsetsResult, offsetsLoadingMessages.length]);

	useEffect(() => {
		if (
			!user?.is_superuser &&
			certification &&
			certification.certification_process_steps[currentStep]?.step
				?.step_type === "OTHER" &&
			(certification.estimations ?? []).find(
				(estimation) => (estimation.product.marketplaces ?? []).length === 0
			) !== undefined
		) {
			setShowAmazonModal(true);
		}
	}, [certification, currentStep, user]);

	const { refetch: refetchCertificationCartSubscriptions } = useQuery(
		"lca-detail",
		() => certificationActions.getCartSubscriptions(certification!.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res: {
				annual_fee_to_pay: number;
				lca_fee_to_pay: number;
				lca_fee_to_pay_multiplier: number;
				revised_lca_fee_to_pay: number;
				revised_lca_fee_to_pay_multiplier: number;
				rejections_lca_fee_to_pay: number;
				subscriptions: Array<ISubscription>;
			}) => {
				setSubscriptions(res.subscriptions);
				setAnnualFeeToPay(res.annual_fee_to_pay);
				setLcaFeeToPay(res.lca_fee_to_pay);
				setLcaFeeToPayMultiplier(res.lca_fee_to_pay_multiplier);
				setRevisedLCAFeeToPay(res.revised_lca_fee_to_pay);
				setRevisedLCAFeeToPayMultiplier(res.revised_lca_fee_to_pay_multiplier);
				setRejectionsLCAFeeToPay(res.rejections_lca_fee_to_pay);
			},
			onError: (error: any) => {
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { isLoading: isLCALoading, refetch: refetchLCA } = useQuery(
		"lca",
		() =>
			lcaActions.getOne(
				certification!.lca_versions![0].lca_id!,
				user?.is_superuser ? undefined : user?.company.id ?? 0
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCA(res);
				if (
					res.lca_versions &&
					res.lca_versions.length > 0 &&
					res.lca_versions[0].certification_process?.id &&
					id &&
					res.lca_versions[0].certification_process!.id !== parseInt(id)
				) {
					window.location.href =
						window.location.origin +
						"/climeco-certificationss/" +
						res.lca_versions![0].certification_process.id;
					//setShowNewLCAVersionModal(true);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		refetch: refetchCertification,
		isLoading: isLoadingRefecthCertification,
	} = useQuery(
		"climeco-certifications-detail",
		() =>
			certificationActions.getOne(
				true,
				parseInt(id!),
				user?.is_superuser ? undefined : user?.company.id
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.is_historical) {
					navigate("/historical-data/" + id);
				} else {
					let step = 0;
					const rejectedStep = res.certification_process_steps.findIndex(
						(step) => step.status === "REJECTED"
					);

					const pendingStep = res.certification_process_steps.findIndex(
						(step) => step.status === "PENDING"
					);

					step =
						rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: res.certification_process_steps.length - 1;

					if (
						res.certification_process_steps[step]?.step?.step_type ===
						"PRODUCT_LIST"
					) {
						res.estimations.map((estimation) => {
							if (!estimation.amount) {
								estimation.amount = undefined;
							}
							return estimation;
						});
					}
					if (res.lca_versions) {
						res.lca_versions.sort(
							(a, b) =>
								new Date(a.created_at!).getTime() -
								new Date(b.created_at!).getTime()
						);
					}
					res.certification_process_steps =
						res.certification_process_steps.filter((step) => step.step);
					setCertification(res);
					setReloadCertification(true);

					const initialLCAProducts = (res: ICertification) => {
						const updatedProducts = Array<IProduct>();
						if (
							(res.lca_versions?.length ?? 0) > 0 &&
							!res.lca_versions?.find(
								(lcaVersion) => lcaVersion.products.length === 0
							)
						) {
							res.lca_versions?.forEach((lcaVersion) => {
								lcaVersion.products?.forEach((p) => {
									if (
										p.product_lca_version_emissions &&
										p.product_lca_version_emissions.length > 0
									) {
										p.product_lca_version_emission =
											p.product_lca_version_emissions[0];
										p.product_lca_version_emission.lca_version = lcaVersion;
									} else {
										p.product_lca_version_emission = {
											id: -new Date().getTime(),
											lca_version: lcaVersion,
										};
									}

									updatedProducts.push(p);
								});
							});
						} else {
							(res.lca_versions ?? []).forEach((lcaVersion) => {
								updatedProducts.push({
									id: new Date().getTime(),
									status: "PENDING" as "PENDING",
									name: "",
									description: "",
									asin: "",
									upc: "",
									lca_documents: [
										{
											id: new Date().getTime(),
											lca_document: undefined,
											certification_date: "",
											lca_provider: "",
											emissions: null,
										},
									],
									product_lca_version_emission: {
										id: -new Date().getTime(),
										lca_version: lcaVersion,
									},
								});
							});
						}

						for (const estimation of res.estimations) {
							for (const product of updatedProducts) {
								if (estimation.product.id === product.id) {
									product.estimations = [estimation];
								}
							}
						}

						return updatedProducts;
					};

					const certificationProducts = initialLCAProducts(res);
					//setCurrentProducts(certificationProducts);
					setProducts(certificationProducts);
					setRefetchedCertification(true);
					setTimeout(() => {
						setIsLoadingProducts(false);
					}, 1000);
				}
			},
			onError: (error: any) => {
				setIsLoadingProducts(false);
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				}
				setRefetchedCertification(true);
			},
		}
	);

	useEffect(() => {
		if (certification && refetchedCertification) {
			refetchBalance();
			refetchCertificationCartSubscriptions();
			if ((certification.lca_versions ?? []).length > 0) {
				refetchLCA();
			}
			setRefetchedCertification(false);
		}
	}, [
		certification,
		refetchCertificationCartSubscriptions,
		refetchLCA,
		refetchBalance,
		refetchedCertification,
	]);

	const getEstimations = () => {
		if (currentStep === 0) {
			return currentProducts.map((product, index) => {
				return {
					id: index,
					value: 0,
					//emissions: 0,
					product: product,
				};
			});
		} else {
			return currentEstimations;
		}
	};

	const {
		isLoading: isLoadingCreateCertification,
		mutate: createCertification,
	} = useMutation(
		"create-certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user!.company.id,
				isHistorical: false,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingCreateEstimation,
		mutate: createEstimationMutate,
	} = useMutation(
		"estimations",
		(estimation: IEstimation) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess:
					estimation.certification_process?.id ?? certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
				is_add_products: estimation.is_add_products,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteEstimation,
		mutate: deleteEstimationMutate,
	} = useMutation(
		"delete-estimations",
		(estimation: IEstimation) =>
			estimationsActions.delete({
				id: estimation.id,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateEstimation,
		mutate: updateEstimationMutate,
	} = useMutation(
		"estimations-update",
		(estimation: IEstimation) =>
			estimationsActions.update({
				id: estimation.id,
				amount: estimation.amount,
				//emissions: estimation.emissions,
				certificationProcess: certification!.id,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { isLoading, mutate } = useMutation(
		"certification-detail",
		() =>
			certificationActions.update({
				isClimeCo: true,
				estimations: getEstimations(),
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
				id: certification?.id ?? -1,
				description: description,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				refetchCertification();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { isLoading: isLoadingMarkItAsPaid, mutate: mutateMarkItAsPaid } =
		useMutation(
			"mark-it-as-paid",
			() =>
				certificationProcessStepActions.markItAsPaid({
					id: certification!.certification_process_steps[currentStep].id,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
					setShowMarkItAsPaidModal(false);
					enqueueSnackbar(
						t("climecoCertificationProcess.markedAsPaidMessage"),
						{
							variant: "success",
						}
					);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	const { isLoading: isLoadingMutateAddOffsets, mutate: mutateAddOffsets } =
		useMutation(
			"certification-add-offsets",
			() =>
				certificationActions.addOffsets({
					isClimeCo: true,
					id: certification?.id ?? -1,
					companyId: certification?.company.id,
					quantity: manualOffsets,
					amount:
						typeof manualOffsetsPrice === "string"
							? parseFloat(manualOffsets!.toString()) * 12.5
							: parseFloat(manualOffsets!.toString()) * manualOffsetsPrice,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					refetchCertification();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	const { isLoading: isLoadingApproval, mutate: mutateApproval } = useMutation(
		"certification-detail-approval",
		() =>
			certificationActions.toggleApproval({
				isClimeCo: true,
				id: certification?.id ?? -1,
				companyId: user?.is_superuser
					? certification?.company.id
					: user?.company.id,
			}),
		{
			retry: false,
			onSuccess: async (res) => {
				await refetchCertification();
				setShowAgreeModal(false);
				/*enqueueSnackbar(
					"Certification requested. Now, waiting for Admin's approval.",
					{ variant: "success" }
				);*/
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchCertificationSteps } = useQuery(
		"fetch-steps",
		() =>
			certificationActions.getSteps("CLIMECO_INTERNAL_CERTIFICATION", false),
		{
			enabled: false,
			retry: false,
			onSuccess: (res: IStep[]) => {
				const certificationSteps = res
					.filter((step) => step)
					.map((step) => {
						return {
							id: new Date().getTime(),
							status: "PENDING",
							payment_status: null,
							reject_reason: "",
							step,
						} as ICertificationSteps;
					});
				setCertification({
					id: -1,
					name: t("climecoCertificationProcess.certificationName", {
						time: new Date().getTime(),
					}),
					certification_process_steps: certificationSteps,
					company: user!.company,
					estimations: Array<IEstimation>(),
					is_historical: false,
					status: "COMPLETED",
				});
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (id && user) {
			if (id === "create") {
				refetchCertificationSteps();
			} else {
				refetchCertification();
			}
		}
	}, [
		id,
		refetchCertification,
		user,
		enqueueSnackbar,
		refetchCertificationSteps,
	]);

	useEffect(() => {
		if (user && certification && currentStep) {
			if (searchParams.get("event") === "ttl_expired") {
				if (
					(!user?.is_superuser &&
						certification.certification_process_steps[currentStep]?.step
							?.step_type === "REDIRECTION") ||
					(user?.is_superuser &&
						certification.certification_process_steps[currentStep]?.step
							?.step_type === "WAITING")
				) {
					enqueueSnackbar(t("climecoCertificationProcess.linkExpired"), {
						variant: "info",
					});
					refetchLink();
				} else {
					searchParams.delete("event");
					setSearchParams(searchParams);
				}
			} else if (searchParams.get("event") !== null) {
				setLoadingDocuSignResult(true);
				enqueueSnackbar(
					t("climecoCertificationProcess.signingSuccessfulMessage"),
					{
						variant: "success",
						autoHideDuration: 25000,
					}
				);
				searchParams.delete("event");
				setSearchParams(searchParams);
				setTimeout(async () => {
					await refetchCertification();
					setLoadingDocuSignResult(false);
				}, 25000);
			}
		}
	}, [
		searchParams,
		certification,
		refetchLink,
		enqueueSnackbar,
		refetchCertification,
		setSearchParams,
		currentStep,
		user,
		t,
	]);

	useEffect(() => {
		if (
			reloadCertification &&
			certification &&
			certification.certification_process_steps
		) {
			const rejectedStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "REJECTED"
			);

			const pendingStep = certification.certification_process_steps.findIndex(
				(step) => step.status === "PENDING"
			);

			if (
				currentStep &&
				currentStep < certification.certification_process_steps.length &&
				certification.certification_process_steps[currentStep]?.step
					?.step_type === "CHOOSE_PRODUCTS"
			) {
				setCurrentStep(currentStep + 1);
			} else {
				let auxCurrentStep =
					rejectedStep > -1
						? rejectedStep
						: pendingStep > -1
						? pendingStep
						: certification.certification_process_steps.length - 1;

				if (
					auxCurrentStep &&
					auxCurrentStep < certification.certification_process_steps.length &&
					certification.certification_process_steps[auxCurrentStep]?.step
						?.step_type === "CERTIFICATION_SUMMARY" &&
					certification.estimations.find((e) => !e.amount)
				) {
					setCurrentStep(auxCurrentStep - 1);
				} else {
					setCurrentStep(auxCurrentStep);
				}
			}
			setReloadCertification(false);
		}
	}, [certification, reloadCertification, currentStep]);

	useEffect(() => {
		if (certification?.is_update_lca && tabValue !== 1) {
			setTabValue(1);
		}
	}, [certification, tabValue]);

	useEffect(() => {
		// if it was rejected (and so there were already products) -> then show directly the manual entry
		if ((certification?.estimations ?? []).length > 0) {
			setTabValue(1);
		}
	}, [certification]);

	useEffect(() => {
		setOpenAdminInfo(
			!user?.is_superuser ||
				(user?.is_superuser &&
				certification &&
				currentStep &&
				currentStep < certification.certification_process_steps.length &&
				certification?.certification_process_steps[currentStep]?.step
					?.step_type === "CERTIFICATION_SUMMARY"
					? true
					: false)
		);
	}, [certification, currentStep, user?.is_superuser]);

	useEffect(() => {
		if (
			!loadingDocuSignResult &&
			certification?.certification_process_steps[currentStep]?.step
				?.step_type === "PURCHASE_OFFSETS" &&
			certification?.certification_process_steps[currentStep].payment_status ===
				"NOT_STARTED" &&
			(!certification?.manual_approval || !user?.is_superuser) &&
			lcaFeeToPay > -1 &&
			!user?.is_superuser
		) {
			setShowPurchaseOffsets(true);
		}
	}, [loadingDocuSignResult, certification, currentStep, user, lcaFeeToPay]);

	const { isLoading: isLoadingCreateProduct, mutate: mutateCreateProduct } =
		useMutation(
			"create-product",
			({ product }: { product: IProduct }) =>
				productActions.create({
					name: product?.name,
					description: product?.description,
					companyId: user?.is_superuser ? product.company_id : user?.company.id,
					external_id: product?.external_id,
					sku: product?.sku,
					asin: product?.asin,
					ean: product?.ean,
					isbn: product?.isbn,
					upc: product?.upc,
					gtin: product?.gtin,
					websites: product?.websites ?? [""],
					status: user?.is_superuser ? product?.status : undefined,
					brand: product?.brand,
				}),
			{
				retry: false,
			}
		);

	const { isLoading: isLoadingUpdateProduct, mutate: mutateUpdateProduct } =
		useMutation(
			"update-product",
			({ product }: { product: IProduct }) =>
				productActions.update({
					id: product.id,
					name: product?.name,
					description: product?.description,
					companyId: user?.is_superuser ? product.company_id : user?.company.id,
					external_id: product?.external_id,
					sku: product?.sku,
					asin: product?.asin,
					ean: product?.ean,
					isbn: product?.isbn,
					upc: product?.upc,
					gtin: product?.gtin,
					websites: product?.websites ?? [""],
					status: user?.is_superuser ? product?.status : undefined,
					brand: product?.brand,
				}),
			{
				retry: false,
			}
		);

	const {
		isLoading: isLoadingCreateProductLCAVersionEmission,
		mutate: mutateCreateProductLCAVersionEmission,
	} = useMutation(
		"create-product-lca-version-emission",
		({
			productLcaVersionEmission,
			lcaVersionId,
			productId,
			lcaId,
			companyId,
		}: {
			productLcaVersionEmission: number;
			lcaVersionId: number;
			productId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.create(
				productLcaVersionEmission,
				lcaVersionId,
				productId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingUpdateProductLCAVersionEmission,
		mutate: mutateUpdateProductLCAVersionEmission,
	} = useMutation(
		"update-product-lca-version-emission",
		({
			id,
			productLcaVersionEmission,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			productLcaVersionEmission: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.update(
				id,
				productLcaVersionEmission,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const {
		isLoading: isLoadingDeleteProductLCAVersionEmission,
		mutate: mutateDeleteProductLCAVersionEmission,
	} = useMutation(
		"delete-product-lca-version-emission",
		({
			id,
			lcaVersionId,
			lcaId,
			companyId,
		}: {
			id: number;
			lcaVersionId: number;
			lcaId: number;
			companyId?: number;
		}) =>
			productLCAVersionEmissionActions.delete(
				id,
				lcaVersionId,
				lcaId,
				companyId
			),
		{
			retry: false,
		}
	);

	const handleKitDownload = () => {
		const fileUrl =
			"/ClimeCo-Certified-Product-Marketing-Kit-and-Brand-Guidelines.zip";

		const link = document.createElement("a");
		link.href = fileUrl;
		link.download =
			"ClimeCo-Certified-Product-Marketing-Kit-and-Brand-Guidelines.zip";

		link.click();
	};

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	useEffect(() => {
		const createProductsProcess = async () => {
			setProgress(0);
			setIsLoadingProducts(true);
			setCreateProducts(false);

			if (certification) {
				let productsIndex = 0;
				for (const newProduct of currentProducts) {
					setProgress((productsIndex / (currentProducts.length * 2)) * 100);
					let found = false;
					for (const lcaVersion of certification.lca_versions ?? []) {
						for (const product of lcaVersion.products ?? []) {
							if (newProduct.id === product.id) {
								found = true;
							}
						}
					}
					const lcaVersion =
						newProduct.product_lca_version_emission?.lca_version ?? null;
					if (!found) {
						const createdProduct: IProduct | null = await new Promise(
							(resolve, reject) => {
								mutateCreateProduct(
									{ product: newProduct },
									{
										onSuccess: (res) => {
											resolve(res as IProduct);
										},
										onError: (error) => {
											console.log(error);
											enqueueSnackbar("Something went wrong.", {
												variant: "error",
											});
											reject(null);
										},
									}
								);
							}
						);
						if (createdProduct) {
							setCurrentProducts((auxCurrentProducts) => {
								const auxProduct = auxCurrentProducts.find(
									(cp) => cp.id === newProduct.id
								);
								if (auxProduct) {
									auxProduct!.id = (createdProduct as IProduct).id;
								}
								return auxCurrentProducts;
							});
							await new Promise((resolve, reject) => {
								mutateCreateProductLCAVersionEmission(
									{
										productLcaVersionEmission:
											newProduct.product_lca_version_emission!.emissions ?? 0,
										lcaVersionId: lcaVersion?.id ?? -1,
										productId: (createdProduct as IProduct).id,
										lcaId: lcaVersion?.lca_id ?? -1,
										companyId: certification.company.id,
									},
									{
										onSuccess: (res) => {
											resolve(res);
										},
										onError: (error) => {
											console.log(error);
											enqueueSnackbar("Something went wrong.", {
												variant: "error",
											});
											reject();
										},
									}
								);
							});
						}
					} else {
						await new Promise((resolve, reject) => {
							mutateUpdateProduct(
								{ product: newProduct },
								{
									onSuccess: (res) => {
										resolve(res as IProduct);
									},
									onError: (error) => {
										console.log(error);
										enqueueSnackbar("Something went wrong.", {
											variant: "error",
										});
										reject(null);
									},
								}
							);
						});

						await new Promise((resolve, reject) => {
							mutateUpdateProductLCAVersionEmission(
								{
									id: newProduct.product_lca_version_emission!.id,
									productLcaVersionEmission:
										newProduct.product_lca_version_emission!.emissions ?? 0,
									lcaVersionId: lcaVersion?.id ?? -1,
									lcaId: lcaVersion?.lca_id ?? -1,
									companyId: certification.company.id,
								},
								{
									onSuccess: (res) => {
										resolve(res);
									},
									onError: (error) => {
										console.log(error);
										enqueueSnackbar(t("general.errorMessage"), {
											variant: "error",
										});
										reject();
									},
								}
							);
						});
					}
					productsIndex += 1;
				}

				for (const lcaVersion of certification.lca_versions ?? []) {
					for (const product of lcaVersion?.products ?? []) {
						let found = false;
						for (const newProduct of currentProducts) {
							if (newProduct.id === product.id) {
								found = true;
							}
						}
						if (!found) {
							// TO DO; CHECK HERE WHY product_lca_version_emission is empty
							await new Promise((resolve, reject) => {
								mutateDeleteProductLCAVersionEmission(
									{
										id: product.product_lca_version_emission!.id,
										lcaVersionId:
											product.product_lca_version_emission!.lca_version!.id,
										/*certification.lca_version!
																												.id,*/
										lcaId:
											product.product_lca_version_emission!.lca_version!
												.lca_id!,
										companyId: certification.company.id,
									},
									{
										onSuccess: (res) => {
											resolve(res);
										},
										onError: (error) => {
											console.log(error);
											enqueueSnackbar(t("general.errorMessage"), {
												variant: "error",
											});
											reject();
										},
									}
								);
							});
						}
					}
				}

				// END TO DO

				let index = 0;

				// TO DO: recheck it here
				// Check here what to really delete

				const createEstimation = async (
					estimationId: number,
					amount: number,
					//emissions: number,
					product: IProduct,
					index: number,
					certificationProcess?: ICertification
				) => {
					productsIndex += 1;
					setProgress((productsIndex / (currentProducts.length * 2)) * 100);
					const onSuccess = async () => {
						index += 1;
						if (index < currentProducts.length) {
							createEstimation(
								index,
								(currentProducts[index].estimations ?? []).length > 0
									? currentProducts[index].estimations![0].amount ?? amount
									: amount,
								//0,
								currentProducts[index],
								index,
								certificationProcess
							);
						} else {
							// delete here estimations
							const auxOriginalEstimations = originalEstimations.filter(
								(e) =>
									currentProducts.findIndex((p) => p.id === e.product.id) === -1
							);
							for (const estimation of auxOriginalEstimations) {
								await new Promise((resolve, reject) => {
									deleteEstimationMutate(estimation, {
										onSuccess: () => {
											resolve(true);
										},
										onError: () => {
											resolve(true);
											//reject();
										},
									});
								});
							}
							if (certificationProcess && id === "create") {
								navigate(
									"/climeco-certificationss/" + certificationProcess!.id
								);
							} else {
								refetchCertification();
							}
						}
					};

					let estimationAlreadyExists = certification.estimations.find(
						(estimation) => currentProducts[index].id === estimation.product.id
					);

					if (estimationAlreadyExists !== undefined) {
						await new Promise((resolve) => {
							updateEstimationMutate(
								{
									id: estimationAlreadyExists!.id,
									amount: amount,
									//emissions: emissions,
									product: product,
								},
								{
									onSuccess: () => {
										resolve(true);
									},
									onError: () => {
										resolve(true);
									},
								}
							);
						});
						onSuccess();
					} else {
						createEstimationMutate(
							{
								id: estimationId,
								amount: amount,
								//emissions: emissions,
								product: product,
								certification_process: certificationProcess,
								is_add_products: certification?.is_add_products,
							},
							{
								onSuccess: () => {
									onSuccess();
								},
								onError: (error) => {
									console.log(error);
									enqueueSnackbar(t("general.errorMessage"), {
										variant: "error",
									});
								},
							}
						);
					}
				};

				if (certification.id === -1) {
					createCertification(null, {
						onSuccess: (res) => {
							createEstimation(
								index,
								currentProducts[index]?.estimations
									? currentProducts[index]?.estimations![0].amount ?? 0
									: 0,
								//0,
								currentProducts[index],
								index,
								res
							);
						},
						onError: (error) => {
							console.log(error);
							enqueueSnackbar(t("general.errorMessage"), {
								variant: "error",
							});
						},
					});
				} else {
					createEstimation(
						index,
						currentProducts[index]?.estimations
							? currentProducts[index]?.estimations![0].amount ?? 0
							: 0,
						//0,
						currentProducts[index],
						index
					);
				}
			}
		};
		if (createProducts) {
			createProductsProcess();
		}
	}, [
		certification,
		createCertification,
		createEstimationMutate,
		createProducts,
		currentProducts,
		deleteEstimationMutate,
		enqueueSnackbar,
		id,
		mutateCreateProduct,
		mutateCreateProductLCAVersionEmission,
		mutateDeleteProductLCAVersionEmission,
		mutateUpdateProduct,
		mutateUpdateProductLCAVersionEmission,
		navigate,
		originalEstimations,
		refetchCertification,
		setCreateProducts,
		t,
		updateEstimationMutate,
	]);

	useEffect(() => {
		if (setNextButtonEnabled) {
			setNextButtonEnabled(
				csvLoading ||
					!(
						currentProducts.length === 0 ||
						!!currentProducts.find(
							(currentProduct) =>
								!currentProduct.name ||
								!currentProduct.brand ||
								!currentProduct.product_lca_version_emission?.emissions ||
								!currentProduct.estimations ||
								(currentProduct.estimations &&
									(currentProduct.estimations.length === 0 ||
										currentProduct.estimations.find((e) => !e.amount)))
						) ||
						currentProducts.filter((r) => r.asin).map((r) => r.asin).length !==
							new Set(currentProducts.filter((r) => r.asin).map((r) => r.asin))
								.size ||
						currentProducts.filter((r) => r.asin && !isValidASIN(r.asin))
							.length > 0
					)
				/*&&
					certification &&
					certification.estimations.find(
						(estimation) =>
							estimation.amount === undefined || estimation.amount === 0
					)
					? false
					: true*/
			);
		}
	}, [currentProducts, csvLoading, setNextButtonEnabled]);

	const offsets = (() => {
		let offsets =
			process.env.REACT_APP_ENV === "stage" ||
			process.env.REACT_APP_DO_NOT_CEIL_BALANCES === "TRUE"
				? (certification?.estimations
						.filter(
							(estimation) =>
								!certification.is_add_products ||
								(certification.is_add_products && estimation.is_add_products)
						)
						.reduce((prev, current) => {
							return (
								prev +
								(current.product_lca_version_emission?.emissions ?? 0) *
									(!isNaN(current.amount ?? 0) && current.amount
										? current.amount
										: 0)
							);
						}, 0) ?? 0) * 0.001
				: certification?.estimations
						.filter(
							(estimation) =>
								!certification.is_add_products ||
								(certification.is_add_products && estimation.is_add_products)
						)
						.reduce((prev, current) => {
							return (
								prev +
								Math.ceil(
									(current.product_lca_version_emission?.emissions ?? 0) *
										(!isNaN(current.amount ?? 0) && current.amount
											? current.amount
											: 0) *
										0.001
								)
							);
						}, 0) ?? 0;
		return Math.ceil(offsets);
	})();

	const offsetsPercentage = user?.email.includes("@climeco")
		? Math.ceil(offsets * 0.1)
		: 0;

	const totalOffsets = (() => {
		return Math.ceil(offsets + offsetsPercentage);
	})();

	return (
		<Box>
			{currentStep ===
				(certification?.certification_process_steps.length ?? 0) - 1 && (
				<Box
					sx={{
						position: "absolute",
						top: "20%",
						left: "50%",
						transform: "translate(-20%, -50%)",
					}}
				>
					<ConfettiExplosion />
				</Box>
			)}

			{!certification ||
			isLoading ||
			isLoadingRefecthCertification ||
			isLCALoading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignContent: "center",
						justifyContent: "center",
						width: "100%",
						minHeight: 200,
						padding: 4,
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : (
				<Box sx={{ position: "relative" }}>
					{certification?.certification_process_steps[currentStep]?.step
						?.step_type === "CERTIFICATION_SUMMARY" && !user?.is_superuser ? (
						!certification?.requested_approval ? (
							<Box
								sx={{
									display: "flex",
									marginBottom: 2,
									borderBottom: "1px solid #D7EBFF",
									paddingTop: 1,
									paddingBottom: 1,
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										paddingLeft: 2,
										paddingRight: 2,
										width: "100%",
										fontSize: "14px",
										color: "#757575",
									}}
								>
									<Typography sx={{ fontSize: 14 }}>
										{t("climecoCertificationProcess.agreeMessage")}
									</Typography>
									<Box
										sx={{
											display: "flex",
											gap: 1,
											justifyContent: "flex-end",
										}}
									>
										<Button
											variant="contained"
											sx={{ maxWidth: 150 }}
											onClick={() => {
												setShowAgreeModal(true);
											}}
										>
											{t("climecoCertificationProcess.agreeButton")}
										</Button>
									</Box>
								</Box>
							</Box>
						) : null
					) : null}

					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
							},
							gap: !matches ? 4 : 0,
							paddingLeft: 4,
							paddingRight: 4,
							overflow: "auto",
							paddingBottom:
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "CERTIFICATION_SUMMARY" ||
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "PURCHASE_OFFSETS" ||
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "REDIRECTION" ||
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "OTHER"
									? "60px"
									: 0,
							//maxHeight: "calc(100vh - 468px)",
						}}
					>
						{certification &&
							certification?.certification_process_steps &&
							certification?.certification_process_steps.length > 0 && (
								<>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Box
												sx={{ display: "flex", flexDirection: "row", gap: 1 }}
											>
												{(certification?.certification_process_steps[
													currentStep
												]?.step?.step_type === "INFORMATION" ||
													certification?.certification_process_steps[
														currentStep
													]?.step?.step_type === "PRODUCT_LIST") /*||
													certification?.certification_process_steps[
														currentStep
													].step.step_type === "CHOOSE_PRODUCTS"*/ && (
													<Button
														variant="outlined"
														onClick={() => {
															if (
																false &&
																certification?.certification_process_steps[
																	currentStep
																]?.step?.step_type === "CHOOSE_PRODUCTS"
															) {
																navigate(
																	"/lcas/" + certification!.lca_version?.lca_id
																);
															} else {
																setCurrentStep(currentStep - 1);
																setOriginalEstimations(
																	certification.estimations
																);
																const initialLCAProducts = (
																	res: ICertification
																) => {
																	const updatedProducts = Array<IProduct>();
																	(res.lca_versions ?? []).forEach(
																		(lcaVersion) => {
																			updatedProducts.push({
																				id: new Date().getTime(),
																				status: "PENDING" as "PENDING",
																				name: "",
																				description: "",
																				asin: "",
																				upc: "",
																				lca_documents: [
																					{
																						id: new Date().getTime(),
																						lca_document: undefined,
																						certification_date: "",
																						lca_provider: "",
																						emissions: null,
																					},
																				],
																				product_lca_version_emission: {
																					id: -new Date().getTime(),
																					lca_version: lcaVersion,
																				},
																			});
																		}
																	);
																	return updatedProducts;
																};
																setCurrentProducts(
																	initialLCAProducts(certification)
																);
															}
														}}
														sx={{ marginRight: 0 }}
													>
														{t("climecoCertificationProcess.backButton")}
													</Button>
												)}
												{!loadingDocuSignResult &&
													certification?.certification_process_steps[
														currentStep
													].step?.button_title && (
														<Button
															variant="contained"
															sx={{
																minHeight: 34,
																display:
																	(certification?.certification_process_steps[
																		currentStep
																	]?.step?.step_type === "WAITING" &&
																		!user?.is_superuser) ||
																	(certification?.certification_process_steps[
																		currentStep
																	]?.step?.step_type === "REDIRECTION" &&
																		user?.is_superuser) ||
																	(certification?.certification_process_steps[
																		currentStep
																	]?.step?.step_type === "PURCHASE_OFFSETS" &&
																		certification?.manual_approval &&
																		user?.is_superuser)
																		? "none"
																		: "block",
															}}
															disabled={(() => {
																switch (
																	certification?.certification_process_steps[
																		currentStep
																	]?.step?.step_type
																) {
																	case "CHOOSE_PRODUCTS": {
																		return (
																			currentProducts.length === 0 ||
																			!!currentProducts.find(
																				(currentProduct) =>
																					!currentProduct.name ||
																					(currentProduct.upc &&
																						currentProduct.upc.length !== 12) ||
																					!currentProduct.brand ||
																					!currentProduct
																						.product_lca_version_emission
																						?.emissions
																			) ||
																			currentProducts
																				.filter((r) => r.asin)
																				.map((r) => r.asin).length !==
																				new Set(
																					currentProducts
																						.filter((r) => r.asin)
																						.map((r) => r.asin)
																				).size ||
																			currentProducts.filter(
																				(r) => r.asin && !isValidASIN(r.asin)
																			).length > 0
																		);
																	}
																	case "INFORMATION": {
																		return !description;
																	}
																	case "PRODUCT_LIST": {
																		return certification.estimations.find(
																			(estimation) =>
																				estimation.amount === undefined ||
																				estimation.amount === 0
																		)
																			? true
																			: false;
																	}
																	case "CERTIFICATION_SUMMARY": {
																		return false;
																	}
																	case "REDIRECTION": {
																		return searchParams.get("event") ||
																			(!user?.is_superuser &&
																				!certification?.climeco_contract_url_customer)
																			? true
																			: false;
																	}
																	case "WAITING": {
																		return (user?.is_superuser ?? false) &&
																			!certification?.climeco_contract_url_admin
																			? true
																			: false;
																	}

																	default: {
																		return false;
																	}
																}
															})()}
															onClick={async () => {
																switch (
																	certification?.certification_process_steps[
																		currentStep
																	]?.step?.step_type
																) {
																	case "CHOOSE_PRODUCTS": {
																		if (
																			(certification.lca_versions ?? []).reduce(
																				(prev, next) =>
																					prev + (next.products ?? []).length,
																				0
																			) > 5
																		) {
																			enqueueSnackbar(
																				t(
																					"climecoCertificationProcess.uploadingProductsMessage"
																				),
																				{
																					variant: "info",
																				}
																			);
																		}
																		for (const newProduct of currentProducts) {
																			let found = false;
																			for (const lcaVersion of certification.lca_versions ??
																				[]) {
																				for (const product of lcaVersion.products ??
																					[]) {
																					if (newProduct.id === product.id) {
																						found = true;
																					}
																				}
																			}
																			const lcaVersion =
																				newProduct.product_lca_version_emission
																					?.lca_version ?? null;
																			if (!found) {
																				const createdProduct: IProduct | null =
																					await new Promise(
																						(resolve, reject) => {
																							mutateCreateProduct(
																								{ product: newProduct },
																								{
																									onSuccess: (res) => {
																										resolve(res as IProduct);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											t("general.errorMessage"),
																											{
																												variant: "error",
																											}
																										);
																										reject(null);
																									},
																								}
																							);
																						}
																					);
																				if (createdProduct) {
																					setCurrentProducts(
																						(auxCurrentProducts) => {
																							const auxProduct =
																								auxCurrentProducts.find(
																									(cp) =>
																										cp.id === newProduct.id
																								);
																							if (auxProduct) {
																								auxProduct!.id = (
																									createdProduct as IProduct
																								).id;
																							}
																							return auxCurrentProducts;
																						}
																					);
																					await new Promise(
																						(resolve, reject) => {
																							mutateCreateProductLCAVersionEmission(
																								{
																									productLcaVersionEmission:
																										newProduct.product_lca_version_emission!
																											.emissions ?? 0,
																									lcaVersionId:
																										lcaVersion?.id ?? -1,
																									productId: (
																										createdProduct as IProduct
																									).id,
																									lcaId:
																										lcaVersion?.lca_id ?? -1,
																									companyId:
																										certification.company.id,
																								},
																								{
																									onSuccess: (res) => {
																										resolve(res);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											t("general.errorMessage"),
																											{
																												variant: "error",
																											}
																										);
																										reject();
																									},
																								}
																							);
																						}
																					);
																				}
																			} else {
																				await new Promise((resolve, reject) => {
																					mutateUpdateProduct(
																						{ product: newProduct },
																						{
																							onSuccess: (res) => {
																								resolve(res as IProduct);
																							},
																							onError: (error) => {
																								console.log(error);
																								enqueueSnackbar(
																									t("general.errorMessage"),
																									{
																										variant: "error",
																									}
																								);
																								reject(null);
																							},
																						}
																					);
																				});

																				await new Promise((resolve, reject) => {
																					mutateUpdateProductLCAVersionEmission(
																						{
																							id: newProduct.product_lca_version_emission!
																								.id,
																							productLcaVersionEmission:
																								newProduct.product_lca_version_emission!
																									.emissions ?? 0,
																							lcaVersionId:
																								lcaVersion?.id ?? -1,
																							lcaId: lcaVersion?.lca_id ?? -1,
																							companyId:
																								certification.company.id,
																						},
																						{
																							onSuccess: (res) => {
																								resolve(res);
																							},
																							onError: (error) => {
																								console.log(error);
																								enqueueSnackbar(
																									t("general.errorMessage"),
																									{
																										variant: "error",
																									}
																								);
																								reject();
																							},
																						}
																					);
																				});
																			}
																		}

																		for (const lcaVersion of certification.lca_versions ??
																			[]) {
																			for (const product of lcaVersion?.products ??
																				[]) {
																				let found = false;
																				for (const newProduct of currentProducts) {
																					if (newProduct.id === product.id) {
																						found = true;
																					}
																				}
																				if (!found) {
																					// TO DO; CHECK HERE WHY product_lca_version_emission is empty
																					await new Promise(
																						(resolve, reject) => {
																							mutateDeleteProductLCAVersionEmission(
																								{
																									id: product.product_lca_version_emission!
																										.id,
																									lcaVersionId:
																										product.product_lca_version_emission!
																											.lca_version!.id,
																									/*certification.lca_version!
																											.id,*/
																									lcaId:
																										product.product_lca_version_emission!
																											.lca_version!.lca_id!,
																									companyId:
																										certification.company.id,
																								},
																								{
																									onSuccess: (res) => {
																										resolve(res);
																									},
																									onError: (error) => {
																										console.log(error);
																										enqueueSnackbar(
																											t("general.errorMessage"),
																											{
																												variant: "error",
																											}
																										);
																										reject();
																									},
																								}
																							);
																						}
																					);
																				}
																			}
																		}

																		// END TO DO

																		let index = 0;

																		const createEstimation = async (
																			estimationId: number,
																			amount: number,
																			//emissions: number,
																			product: IProduct,
																			index: number,
																			certificationProcess?: ICertification
																		) => {
																			const onSuccess = async () => {
																				index += 1;
																				if (index < currentProducts.length) {
																					createEstimation(
																						index,
																						(
																							currentProducts[index]
																								.estimations ?? []
																						).length > 0
																							? currentProducts[index]
																									.estimations![0].amount ??
																									amount
																							: amount,
																						//0,
																						currentProducts[index],
																						index,
																						certificationProcess
																					);
																				} else {
																					const auxOriginalEstimations =
																						originalEstimations.filter(
																							(e) =>
																								currentProducts.findIndex(
																									(p) => p.id === e.product.id
																								) === -1
																						);
																					for (const estimation of auxOriginalEstimations) {
																						await new Promise(
																							(resolve, reject) => {
																								deleteEstimationMutate(
																									estimation,
																									{
																										onSuccess: () => {
																											resolve(true);
																										},
																										onError: () => {
																											resolve(true);
																											//reject();
																										},
																									}
																								);
																							}
																						);
																					}
																					if (
																						certificationProcess &&
																						id === "create"
																					) {
																						navigate(
																							"/climeco-certificationss/" +
																								certificationProcess!.id
																						);
																					} else {
																						refetchCertification();
																					}
																				}
																			};

																			let estimationAlreadyExists =
																				certification.estimations.find(
																					(estimation) =>
																						currentProducts[index].id ===
																						estimation.product.id
																				) !== undefined;

																			if (estimationAlreadyExists) {
																				onSuccess();
																			} else {
																				createEstimationMutate(
																					{
																						id: estimationId,
																						amount: amount,
																						//emissions: emissions,
																						product: product,
																						certification_process:
																							certificationProcess,
																						is_add_products:
																							certification?.is_add_products,
																					},
																					{
																						onSuccess: () => {
																							onSuccess();
																						},
																						onError: (error) => {
																							console.log(error);
																							enqueueSnackbar(
																								t("general.errorMessage"),
																								{
																									variant: "error",
																								}
																							);
																						},
																					}
																				);
																			}
																		};

																		if (certification.id === -1) {
																			createCertification(null, {
																				onSuccess: (res) => {
																					createEstimation(
																						index,
																						currentProducts[index]?.estimations
																							? currentProducts[index]
																									?.estimations![0].amount ?? 0
																							: 0,
																						//0,
																						currentProducts[index],
																						index,
																						res
																					);
																				},
																				onError: (error) => {
																					console.log(error);
																					enqueueSnackbar(
																						t("general.errorMessage"),
																						{
																							variant: "error",
																						}
																					);
																				},
																			});
																		} else {
																			createEstimation(
																				index,
																				currentProducts[index]?.estimations
																					? currentProducts[index]
																							?.estimations![0].amount ?? 0
																					: 0,
																				//0,
																				currentProducts[index],
																				index
																			);
																		}

																		break;
																	}
																	case "INFORMATION": {
																		mutate();
																		break;
																	}
																	case "REDIRECTION": {
																		if (
																			!user?.is_superuser &&
																			certification?.climeco_contract_url_customer
																		) {
																			window.location.href =
																				certification?.climeco_contract_url_customer;
																		}
																		break;
																	}
																	case "WAITING": {
																		if (
																			(user?.is_superuser ?? false) &&
																			certification?.climeco_contract_url_admin
																		) {
																			window.location.href =
																				certification?.climeco_contract_url_admin;
																		}
																		break;
																	}
																	case "PRODUCT_LIST": {
																		let index = 0;

																		const updateEstimation = (
																			id: number,
																			amount: number,
																			//emissions: number,
																			product: IProduct,
																			index: number
																		) => {
																			updateEstimationMutate(
																				{
																					id: id,
																					amount: amount,
																					//emissions: emissions,
																					product: product,
																				},
																				{
																					onSuccess: () => {
																						index += 1;
																						if (
																							index <
																							certification.estimations.length
																						) {
																							updateEstimation(
																								certification.estimations[index]
																									.id,
																								certification.estimations[index]
																									.amount!,
																								/*certification.estimations[index]
																						.emissions!,*/
																								certification.estimations[index]
																									.product,
																								index
																							);
																						} else {
																							refetchCertification();
																						}
																						setShowConfirmation(false);
																					},
																					onError: (error) => {
																						console.log(error);
																						enqueueSnackbar(
																							t("general.errorMessage"),
																							{
																								variant: "error",
																							}
																						);
																						setShowConfirmation(false);
																					},
																				}
																			);
																		};

																		updateEstimation(
																			certification.estimations[index].id,
																			certification.estimations[index].amount!,
																			//certification.estimations[index].emissions!,
																			certification.estimations[index].product,
																			index
																		);

																		break;
																	}
																	case "OTHER": {
																		navigate("/products");
																		break;
																	}
																	default: {
																		if (
																			currentStep + 1 <
																			certification.certification_process_steps
																				.length
																		) {
																			setCurrentStep(currentStep + 1);
																		}
																	}
																}
															}}
														>
															{isLoadingCreateEstimation ||
															isLoadingUpdateEstimation ||
															isLoading ||
															isLoadingDeleteEstimation ||
															isLoadingCreateCertification ||
															isLoadingCreateProduct ||
															isLoadingUpdateProduct ||
															isLoadingCreateProductLCAVersionEmission ||
															isLoadingUpdateProductLCAVersionEmission ||
															isLoadingDeleteProductLCAVersionEmission ? (
																<CircularProgress
																	size={12}
																	sx={{ color: "white" }}
																/>
															) : (
																certification?.certification_process_steps[
																	currentStep
																].step?.button_title ??
																t("climecoCertificationProcess.continue")
															)}
														</Button>
													)}
											</Box>
										</Box>
										{loadingDocuSignResult && (
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													padding: 4,
													flexDirection: "column",
												}}
											>
												<CircularProgress size={24} />
												<motion.div
													key={messagesIndex}
													initial={{ opacity: 0, x: -100 }}
													animate={{ opacity: 1, x: 0 }}
													exit={{ opacity: 0, x: 100 }}
												>
													<Typography
														variant="body2"
														sx={{
															marginTop: 2,
															maxWidth: 400,
															textAlign: "center",
														}}
													>
														{docuSignLoadingMessages[messagesIndex]}
													</Typography>
												</motion.div>
											</Box>
										)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "CHOOSE_PRODUCTS" &&
											(isLoadingProducts ? (
												<LoadingBar progress={progress} />
											) : (
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														padding: { xs: 0, md: 3 },
														flex: 1,
													}}
												>
													{/*<Select
														variant="outlined"
														value={selectedLCA}
														onChange={(event) =>
															setSelectedLCA(
																typeof event.target.value === "number"
																	? event.target.value
																	: parseInt(event.target.value)
															)
														}
														displayEmpty
														sx={{
															flex: 1,
															width: "100%",
															color: "#000000",
															backgroundColor: "#ffffff",
															"& .MuiSelect-select": {
																backgroundColor: "#ffffff",
																color: "#000000",
																padding: "8px",
															},
															"& .MuiSelect-placeholder": {
																color: "#757575",
															},
															"& fieldset": {
																border: "1px solid #E4E4E4",
															},
														}}
														renderValue={(value) => {
															return (
																<Typography
																	variant="h6"
																	sx={{
																		color: "#333",
																		fontSize: 14,
																	}}
																>
																	Entering Products for{" "}
																	<span
																		style={{
																			fontWeight: "bold",
																		}}
																	>
																		{certification?.lca_versions &&
																		certification?.lca_versions.length > value
																			? certification?.lca_versions![value]
																					?.title ?? ""
																			: ""}
																	</span>
																</Typography>
															);
														}}
													>
														{(certification?.lca_versions ?? []).map(
															(lca, index) => (
																<MenuItem key={lca?.id} value={index}>
																	{lca?.title ?? lca?.lca?.title ?? index}
																</MenuItem>
															)
														)}
													</Select>*/}
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															width: "100%",
															backgroundColor: "#ffffff",
															gap: 3,
														}}
													>
														{certification?.lca_versions?.map((lca, index) => (
															<LCACollapsible
																key={lca?.id}
																title={
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "space-between",
																			alignItems: "center",
																			margin: 0,
																		}}
																	>
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: "column",
																				alignItems: "flex-start",
																				paddingLeft: 1,
																			}}
																		>
																			<Typography
																				variant="h6"
																				sx={{
																					color: "#333",
																					fontSize: 14,
																					fontWeight: "bold",
																				}}
																			>
																				{lca?.title ??
																					lca?.lca?.title ??
																					`${t(
																						"climecoCertificationProcess.lcaTitle"
																					)} ${index}`}
																				{lca?.created_at &&
																					" - " +
																						new Intl.DateTimeFormat("en", {
																							day: "2-digit",
																							month: "2-digit",
																							year: "numeric",
																							timeZone: "UTC",
																						}).format(
																							new Date(lca!.created_at)
																						)}
																			</Typography>
																		</Box>
																		<Box>
																			<Typography
																				variant="body2"
																				sx={{
																					fontSize: "14px",
																					color: "#757575",
																					flex: 1,
																					textAlign: "left",
																					marginBottom: { xs: 2, md: 0 },
																				}}
																			>
																				{
																					currentProducts.filter(
																						(p) =>
																							p.product_lca_version_emission
																								?.lca_version?.id === lca.id &&
																							p.name
																					).length
																				}
																				/{lca.number_of_products ?? ""}{" "}
																				{t(
																					"climecoCertificationProcess.totalProducts"
																				)}
																			</Typography>
																		</Box>
																	</Box>
																}
																defaultExpanded={selectedLCA === index}
																expanded={expandedPanel === `panel-${index}`}
																onChange={() => handleExpand(`panel-${index}`)}
															>
																<Box sx={{}}>
																	{(certification?.lca_versions ?? [])
																		.filter(
																			(lcaVersion) =>
																				lcaVersion.id ===
																				(certification?.lca_versions ?? [])[
																					index
																				].id
																		)
																		.map((lcaVersion) => (
																			<Box key={lcaVersion.id}>
																				<Tabs
																					value={tabValue}
																					onChange={handleTabChange}
																					aria-label="upload products tabs"
																					sx={{
																						width: "100%",
																						"& .MuiTabs-flexContainer": {
																							justifyContent: "space-between",
																						},
																						"& .MuiTab-root": {
																							textTransform: "none",
																							flex: 1,
																						},
																					}}
																				>
																					<Tab
																						label={
																							<Box>
																								<Typography
																									variant="body1"
																									sx={{
																										fontWeight: "bold",
																										fontSize: 14,
																										textAlign: "center",
																									}}
																								>
																									{t(
																										"bulkProductsTable.batchUpload"
																									)}
																								</Typography>
																								<Typography
																									variant="body2"
																									sx={{
																										fontWeight: "normal",
																										fontSize: 12,
																										textAlign: "center",
																									}}
																								>
																									{t(
																										"bulkProductsTable.bestFor20Plus"
																									)}
																								</Typography>
																							</Box>
																						}
																						disabled={
																							certification.is_update_lca
																						}
																					/>
																					<Tab
																						label={
																							<Box>
																								<Typography
																									variant="body1"
																									sx={{
																										fontWeight: "bold",
																										fontSize: 14,
																										textAlign: "center",
																									}}
																								>
																									{t(
																										"bulkProductsTable.manualEntry"
																									)}
																								</Typography>
																								<Typography
																									variant="body2"
																									sx={{
																										fontWeight: "normal",
																										fontSize: 12,
																										textAlign: "center",
																									}}
																								>
																									{t(
																										"bulkProductsTable.bestFor20OrFewer"
																									)}
																								</Typography>
																							</Box>
																						}
																					/>
																				</Tabs>
																				<Box>
																					{tabValue === 1 && (
																						<BulkProductsTable
																							lcaVersion={lcaVersion}
																							newIntegration
																							initialProducts={products.filter(
																								(p) =>
																									p.product_lca_version_emission
																										?.lca_version?.id ===
																									lcaVersion.id
																							)}
																							setInitialProducts={(
																								newProducts: Array<IProduct>
																							) => {
																								let updatedProducts =
																									products.filter(
																										(p: IProduct) =>
																											p
																												.product_lca_version_emission
																												?.lca_version?.id !==
																											lcaVersion.id
																									);
																								setProducts([
																									...updatedProducts,
																									...newProducts,
																								]);
																							}}
																							certification={certification}
																							setCertification={
																								setCertification
																							}
																							csvLoading={csvLoading}
																							setCsvLoading={setCsvLoading}
																							showCSVUploadModal={
																								showCSVUploadModal &&
																								selectedLCA === index
																							}
																							setShowCSVUploadModal={
																								setShowCSVUploadModal
																							}
																							handleEvent={
																								expandedPanel ===
																								`panel-${index}`
																									? handleEvent
																									: null
																							}
																							setHandleEvent={setHandleEvent}
																						/>
																					)}
																					{tabValue === 0 && (
																						<UploadProductsCSV
																							setHandleEvent={setHandleEvent}
																							setTabValue={setTabValue}
																						/>
																					)}
																				</Box>
																			</Box>
																		))}
																</Box>
															</LCACollapsible>
														))}
													</Box>
												</Box>
											))}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "INFORMATION" && (
												<Box sx={{ flex: 1 }}>
													<TextField
														sx={{ width: "100%" }}
														autoFocus
														multiline
														rows={8}
														placeholder={t(
															"climecoCertificationProcess.writeHere"
														)}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
												</Box>
											)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "PRODUCT_LIST" && (
												<Box>
													{(certification?.lca_versions ?? []).map(
														(lcaVersion, index) => (
															<Box sx={{}} key={lcaVersion.id}>
																<LCACollapsible
																	title={
																		<Typography
																			variant="h6"
																			sx={{
																				fontWeight: "bold",
																				color: "#333",
																				fontSize: 14,
																			}}
																		>
																			{t(
																				"climecoCertificationProcess.lcaTitle"
																			)}
																			:{" "}
																			{lcaVersion?.title ??
																				lcaVersion?.lca?.title ??
																				index}
																			{lcaVersion?.created_at &&
																				" - " +
																					new Intl.DateTimeFormat("en", {
																						day: "2-digit",
																						month: "2-digit",
																						year: "numeric",
																						timeZone: "UTC",
																					}).format(
																						new Date(lcaVersion!.created_at)
																					)}
																		</Typography>
																	}
																	defaultExpanded
																>
																	<Typography
																		variant="subtitle1"
																		sx={{
																			fontWeight: "bold",
																			color: "#444",
																			marginTop: "12px",
																			fontSize: 14,
																		}}
																	>
																		{t(
																			"climecoCertificationProcess.estimations"
																		)}
																	</Typography>

																	<Divider sx={{ marginBottom: "16px" }} />
																	{certification?.estimations &&
																		certification?.estimations
																			.filter(
																				(estimation) =>
																					estimation
																						.product_lca_version_emission
																						?.lca_version?.id === lcaVersion.id
																			)
																			.filter(
																				(estimation) =>
																					!certification.is_add_products ||
																					(certification.is_add_products &&
																						estimation.is_add_products)
																			)
																			.sort((a, b) => {
																				const dateA = new Date(
																					a.created_at ?? ""
																				).getTime();
																				const dateB = new Date(
																					b.created_at ?? ""
																				).getTime();

																				return dateA - dateB;
																			})
																			.map((estimation, index) => {
																				return (
																					<Box
																						key={estimation.id}
																						sx={{
																							display: "flex",
																							flexDirection: "column",
																						}}
																					>
																						<Box
																							sx={{
																								display: "flex",
																								alignItems: "center",
																								flexDirection: {
																									xs: "column",
																									sm: "column",
																									md: "row",
																								},
																								gap: 2,
																								flex: 1,
																							}}
																						>
																							<Typography
																								flex={1}
																								sx={{
																									marginTop: "20px",
																									flex: 1,
																									maxWidth: 400,
																								}}
																							>
																								{estimation.product.asin
																									? estimation.product.name +
																									  " - " +
																									  estimation.product.asin
																									: estimation.product.upc
																									? estimation.product.name +
																									  " - " +
																									  estimation.product.upc
																									: estimation.product.sku
																									? estimation.product.name +
																									  " - " +
																									  estimation.product.sku
																									: estimation.product.name}
																							</Typography>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									width: "100%",
																									flex: 1,
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									{t(
																										"climecoCertificationProcess.emissions"
																									)}
																								</span>

																								<NumericFormat
																									value={
																										estimation
																											.product_lca_version_emission
																											?.emissions ?? 0
																									}
																									name={"emissions"}
																									placeholder="kg of CO2e"
																									customInput={TextField}
																									style={{
																										background: "white",
																									}}
																									suffix={" kg of CO2e"}
																									type="text"
																									allowNegative={false}
																									disabled={true}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {}}
																								/>
																							</Box>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									width: "100%",
																									flex: 1,
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									{t(
																										"climecoCertificationProcess.estimatedTotalUnitsSoldFor12Months"
																									)}
																								</span>

																								<NumericFormat
																									value={estimation.amount}
																									style={{
																										border:
																											"1px solid rgb(3, 176, 147)",
																										borderRadius: 4,
																									}}
																									name={"amount"}
																									customInput={TextField}
																									type="text"
																									allowNegative={false}
																									decimalScale={0}
																									autoFocus={index === 0}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {
																										const auxCertification = {
																											...certification,
																										};
																										let estimationIndex =
																											auxCertification.estimations.findIndex(
																												(e) =>
																													e.id === estimation.id
																											);
																										if (estimationIndex > -1) {
																											auxCertification.estimations[
																												estimationIndex
																											].amount = v
																												? parseInt(v)
																												: undefined;
																											setCertification(
																												auxCertification
																											);
																										}
																									}}
																								/>
																							</Box>
																							<Box
																								sx={{
																									display: "flex",
																									flexDirection: "column",
																									textAlign: "right",
																									paddingRight: "20px",
																									"& input": {
																										width: "60px",
																										textAlign: "right",
																									},
																								}}
																							>
																								<span
																									className={classes.inputLabel}
																								>
																									{t(
																										"climecoCertificationProcess.offsetsMT"
																									)}
																								</span>

																								<NumericFormat
																									value={Intl.NumberFormat(
																										"en",
																										{
																											maximumFractionDigits: 0,
																											minimumFractionDigits: 0,
																										}
																									).format(
																										Math.ceil(
																											(estimation
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(estimation.amount ??
																													0) *
																												0.001 ?? 0
																										)
																									)}
																									name={"offsets"}
																									placeholder={t(
																										"climecoCertificationProcess.offsetsPlaceholder"
																									)}
																									customInput={TextField}
																									style={{
																										background: "white",
																									}}
																									type="text"
																									disabled={true}
																									thousandSeparator={","}
																									onValueChange={({
																										value: v,
																									}) => {}}
																								/>
																							</Box>
																						</Box>
																						<Divider
																							sx={{
																								marginTop: 2,
																								marginBottom: 2,
																								opacity: 0.5,
																							}}
																						/>
																					</Box>
																				);
																			})}
																</LCACollapsible>
															</Box>
														)
													)}
													<Box
														sx={{
															backgroundColor: "#fafafa",
															padding: 4,
															display: "flex",
															gap: 12,
															justifyContent: {
																xs: "space-between",
																sm: "space-between",
																md: "flex-end",
															},
														}}
													>
														<Typography>
															{t(
																"climecoCertificationProcess.offsetsToBePurchased"
															)}
														</Typography>
														<Typography>
															{Intl.NumberFormat("en", {
																maximumFractionDigits: 0,
																minimumFractionDigits: 0,
															}).format(
																Math.ceil(
																	process.env.REACT_APP_ENV === "stage" ||
																		process.env
																			.REACT_APP_DO_NOT_CEIL_BALANCES === "TRUE"
																		? (certification?.estimations
																				.filter(
																					(estimation) =>
																						!certification.is_add_products ||
																						(certification.is_add_products &&
																							estimation.is_add_products)
																				)
																				.reduce((prev, current) => {
																					return (
																						prev +
																						(current
																							.product_lca_version_emission
																							?.emissions ?? 0) *
																							(!isNaN(current.amount ?? 0) &&
																							current.amount
																								? current.amount
																								: 0)
																					);
																				}, 0) ?? 0) * 0.001
																		: certification?.estimations
																				.filter(
																					(estimation) =>
																						!certification.is_add_products ||
																						(certification.is_add_products &&
																							estimation.is_add_products)
																				)
																				.reduce((prev, current) => {
																					return (
																						prev +
																						Math.ceil(
																							(current
																								.product_lca_version_emission
																								?.emissions ?? 0) *
																								(!isNaN(current.amount ?? 0) &&
																								current.amount
																									? current.amount
																									: 0) *
																								0.001
																						)
																					);
																				}, 0) ?? 0
																)
															)}{" "}
															{t("climecoCertificationProcess.mt")}{" "}
														</Typography>
													</Box>
												</Box>
											)}

										{!loadingDocuSignResult &&
											certification &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "OTHER" && (
												<Box
													sx={{
														marginTop: 2,
														marginBottom: 2,
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														gap: 2,
													}}
												>
													<Typography>
														{t(
															"climecoCertificationProcess.marketingKitMessage"
														)}
													</Typography>
													<Button
														variant="contained"
														sx={{ width: 200 }}
														onClick={() => {
															handleKitDownload();
														}}
													>
														{t("climecoCertificationProcess.downloadKitButton")}
													</Button>
												</Box>
											)}

										{!loadingDocuSignResult &&
											(certification?.certification_process_steps[currentStep]
												?.step?.step_type === "CERTIFICATION_SUMMARY" ||
												(user?.is_superuser &&
													(certification?.certification_process_steps[
														currentStep
													]?.step?.step_type === "REDIRECTION" ||
														certification?.certification_process_steps[
															currentStep
														]?.step?.step_type === "PURCHASE_OFFSETS" ||
														certification?.certification_process_steps[
															currentStep
														]?.step?.step_type === "OTHER"))) && (
												<Box>
													{certification.certification_process_steps[
														currentStep
													].reject_reason && (
														<Alert severity="error" sx={{ marginBottom: 4 }}>
															{t("climecoCertificationProcess.rejectedMessage")}{" "}
															{
																certification.certification_process_steps[
																	currentStep
																].reject_reason
															}
														</Alert>
													)}
													{user?.is_superuser &&
														(certification?.certification_process_steps[
															currentStep
														]?.step?.step_type === "REDIRECTION" ||
															certification?.certification_process_steps[
																currentStep
															]?.step?.step_type === "PURCHASE_OFFSETS" ||
															certification?.certification_process_steps[
																currentStep
															]?.step?.step_type === "OTHER") && (
															<Box sx={{ marginTop: 8, marginBottom: 4 }}>
																<Button
																	sx={{
																		textTransform: "uppercase",
																		fontSize: 12,
																		fontWeight: "bold",
																	}}
																	endIcon={
																		<ChevronLeft
																			sx={{
																				transform:
																					"rotate(" +
																					(openAdminInfo ? "" : "-") +
																					"90deg)",
																			}}
																		/>
																	}
																	onClick={() => {
																		setOpenAdminInfo(
																			(openAdminInfo) => !openAdminInfo
																		);
																	}}
																>
																	{t("climecoCertificationProcess.adminInfo")}
																</Button>
																<Divider />
															</Box>
														)}
													{certification?.estimations && openAdminInfo && (
														<ProductSummarys
															certification={certification}
															setCertification={setCertification}
															refetchCertification={refetchCertification}
															setShowConfirmation={setShowConfirmation}
															setConfirmationData={setConfirmationData}
															currentStep={currentStep}
															setIsLoadingAdminApproval={
																setIsLoadingAdminApproval
															}
														/>
													)}
												</Box>
											)}

										{(certification.certification_process_steps[currentStep]
											?.step?.step_type === "CERTIFICATION_SUMMARY" ||
											(user?.is_superuser &&
												(certification?.certification_process_steps[currentStep]
													?.step?.step_type === "REDIRECTION" ||
													certification?.certification_process_steps[
														currentStep
													]?.step?.step_type === "PURCHASE_OFFSETS" ||
													certification?.certification_process_steps[
														currentStep
													]?.step?.step_type === "OTHER") &&
												openAdminInfo)) && (
											<Grid
												container
												spacing={2}
												sx={{
													flex: 1,
													marginTop: -4,
													marginBottom: 7,
													display: "flex",
													justifyContent:
														user && user.is_superuser
															? "space-between"
															: "flex-end",
												}}
											>
												<Grid
													item
													xs={12}
													sm={12}
													md={12}
													lg={12}
													sx={{
														height: { md: "auto", lg: "100%" },
														display: "flex",
														flexDirection: "column",
													}}
												>
													<Box
														sx={{
															backgroundColor: "#FAFDFF",
															padding: 4,
															display: "flex",
															flexDirection: "column",
															borderTop: "1px solid #D7EBFF",
															borderBottom: "1px solid #D7EBFF",
															textAlign: "left",
														}}
													>
														{!certification?.is_update_lca && (
															<>
																<Box
																	sx={{
																		display: "flex",
																		justifyContent: "space-between",
																		flexDirection: {
																			xs: "column",
																			sm: "row",
																		},
																	}}
																>
																	<Typography>
																		{t(
																			"climecoCertificationProcess.priceOfOffsetsToBePurchased"
																		)}
																	</Typography>
																	<Typography>
																		{Intl.NumberFormat("en", {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																			style: "currency",
																			currency: "usd",
																		}).format(12.5)}{" "}
																		x{" "}
																		{Intl.NumberFormat("en", {
																			maximumFractionDigits: 0,
																			minimumFractionDigits: 0,
																		}).format(offsets)}{" "}
																		={" "}
																		{Intl.NumberFormat("en", {
																			maximumFractionDigits: 2,
																			minimumFractionDigits: 2,
																			style: "currency",
																			currency: "usd",
																		}).format(offsets * 12.5)}
																	</Typography>
																</Box>
																{offsetsPercentage > 0 && (
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "space-between",
																			flexDirection: {
																				xs: "column",
																				sm: "row",
																			},
																		}}
																	>
																		<Typography>
																			{t(
																				"certificationAgree.offsetsPercentage"
																			)}
																		</Typography>
																		<Typography>
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(12.5)}{" "}
																			x{" "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 0,
																				minimumFractionDigits: 0,
																			}).format(offsetsPercentage)}{" "}
																			={" "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(offsetsPercentage * 12.5)}
																		</Typography>
																	</Box>
																)}
															</>
														)}
														{Math.ceil(
															process.env.REACT_APP_ENV === "stage" ||
																process.env.REACT_APP_DO_NOT_CEIL_BALANCES ===
																	"TRUE"
																? (certification?.estimations
																		.filter(
																			(estimation) =>
																				!certification.is_add_products ||
																				(certification.is_add_products &&
																					estimation.is_add_products)
																		)
																		.reduce((prev, current) => {
																			return (
																				prev +
																				(current.product_lca_version_emission
																					?.emissions ?? 0) *
																					(!isNaN(current.amount ?? 0) &&
																					current.amount
																						? current.amount
																						: 0)
																			);
																		}, 0) ?? 0) * 0.001
																: certification?.estimations
																		.filter(
																			(estimation) =>
																				!certification.is_add_products ||
																				(certification.is_add_products &&
																					estimation.is_add_products)
																		)
																		.reduce((prev, current) => {
																			return (
																				prev +
																				Math.ceil(
																					(current.product_lca_version_emission
																						?.emissions ?? 0) *
																						(!isNaN(current.amount ?? 0) &&
																						current.amount
																							? current.amount
																							: 0) *
																						0.001
																				)
																			);
																		}, 0) ?? 0
														) *
															12.5 +
															annualFeeToPay +
															lcaFeeToPay +
															revisedLCAFeeToPay /*-
															(balance > 0 ? balance : 0)*/ <
															5000 &&
															(!certification.company.first_time_fee_paid ||
																(certification.company.first_time_fee_paid &&
																	new Date(
																		certification.company.first_time_fee_paid
																	).getTime() <
																		new Date().getTime() -
																			365 * 24 * 60 * 60 * 1000)) && (
																<>
																	<Box
																		sx={{
																			display: "flex",
																			justifyContent: "space-between",
																			marginTop: 1,
																		}}
																	>
																		<Typography
																			sx={{
																				maxWidth: 320,
																				color: "#EB4B4B",
																				textAlign: "left",
																			}}
																		>
																			{t(
																				"climecoCertificationProcess.priceOfAdditionalOffsets"
																			)}
																		</Typography>

																		<Typography
																			sx={{
																				color: "#EB4B4B",
																				textAlign: "right",
																			}}
																		>
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(12.5)}{" "}
																			x{" "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 0,
																				minimumFractionDigits: 0,
																			}).format(
																				(5000 -
																					(Math.ceil(
																						process.env.REACT_APP_ENV ===
																							"stage" ||
																							process.env
																								.REACT_APP_DO_NOT_CEIL_BALANCES ===
																								"TRUE"
																							? (certification?.estimations
																									.filter(
																										(estimation) =>
																											!certification.is_add_products ||
																											(certification.is_add_products &&
																												estimation.is_add_products)
																									)
																									.reduce((prev, current) => {
																										return (
																											prev +
																											(current
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(!isNaN(
																													current.amount ?? 0
																												) && current.amount
																													? current.amount
																													: 0)
																										);
																									}, 0) ?? 0) * 0.001
																							: certification?.estimations
																									.filter(
																										(estimation) =>
																											!certification.is_add_products ||
																											(certification.is_add_products &&
																												estimation.is_add_products)
																									)
																									.reduce((prev, current) => {
																										return (
																											prev +
																											Math.ceil(
																												(current
																													.product_lca_version_emission
																													?.emissions ?? 0) *
																													(!isNaN(
																														current.amount ?? 0
																													) && current.amount
																														? current.amount
																														: 0) *
																													0.001
																											)
																										);
																									}, 0) ?? 0
																					) *
																						12.5 +
																						annualFeeToPay +
																						lcaFeeToPay +
																						revisedLCAFeeToPay)) /*+
																						rejectionsLCAFeeToPay*/ /*-
																				(balance > 0 ? balance : 0)*/ /
																					12.5
																			)}
																			{" = "}
																			{Intl.NumberFormat("en", {
																				maximumFractionDigits: 2,
																				minimumFractionDigits: 2,
																				style: "currency",
																				currency: "usd",
																			}).format(
																				5000 -
																					(Math.ceil(
																						process.env.REACT_APP_ENV ===
																							"stage" ||
																							process.env
																								.REACT_APP_DO_NOT_CEIL_BALANCES ===
																								"TRUE"
																							? (certification?.estimations
																									.filter(
																										(estimation) =>
																											!certification.is_add_products ||
																											(certification.is_add_products &&
																												estimation.is_add_products)
																									)
																									.reduce((prev, current) => {
																										return (
																											prev +
																											(current
																												.product_lca_version_emission
																												?.emissions ?? 0) *
																												(!isNaN(
																													current.amount ?? 0
																												) && current.amount
																													? current.amount
																													: 0)
																										);
																									}, 0) ?? 0) * 0.001
																							: certification?.estimations
																									.filter(
																										(estimation) =>
																											!certification.is_add_products ||
																											(certification.is_add_products &&
																												estimation.is_add_products)
																									)
																									.reduce((prev, current) => {
																										return (
																											prev +
																											Math.ceil(
																												(current
																													.product_lca_version_emission
																													?.emissions ?? 0) *
																													(!isNaN(
																														current.amount ?? 0
																													) && current.amount
																														? current.amount
																														: 0) *
																													0.001
																											)
																										);
																									}, 0) ?? 0
																					) *
																						12.5 +
																						annualFeeToPay +
																						lcaFeeToPay +
																						revisedLCAFeeToPay) /*+
																						rejectionsLCAFeeToPay*/ /*-
																				(balance > 0 ? balance : 0)*/
																			)}
																		</Typography>
																	</Box>
																	<Typography
																		sx={{
																			fontStyle: "italic",
																			marginBottom: 2,
																			textAlign: "left",
																		}}
																	>
																		<small>
																			{t(
																				"climecoCertificationProcess.additionalOffsetsNote"
																			)}
																		</small>
																	</Typography>
																</>
															)}

														{annualFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																}}
															>
																<Typography>
																	{subscriptions.find(
																		(s) => s.subscription_type === "ANNUAL_FEE"
																	)?.title ?? "Annual fee"}
																	:
																</Typography>
																<Typography>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(annualFeeToPay)}
																</Typography>
															</Box>
														)}

														{lcaFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																}}
															>
																<Typography>
																	{subscriptions.find(
																		(s) => s.subscription_type === "LCA_FEE"
																	)?.title ?? "LCA fee"}
																	:
																</Typography>
																<Typography textAlign={"right"}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(
																		lcaFeeToPay / lcaFeeToPayMultiplier
																	)}{" "}
																	x{" "}
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 0,
																		minimumFractionDigits: 0,
																	}).format(lcaFeeToPayMultiplier)}
																	{" = "}
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(lcaFeeToPay)}
																</Typography>
															</Box>
														)}
														{revisedLCAFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography>
																	{subscriptions.find(
																		(s) =>
																			s.subscription_type === "UPDATED_LCA_FEE"
																	)?.title ??
																		t(
																			"climecoCertificationProcess.revisionLCAFee"
																		)}
																	:
																</Typography>
																<Typography textAlign={"right"}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(revisedLCAFeeToPay)}
																</Typography>
															</Box>
														)}
														{rejectionsLCAFeeToPay > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography>
																	{t(
																		"climecoCertificationProcess.lcaRejectionFee"
																	)}
																</Typography>
																<Typography textAlign={"right"}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(rejectionsLCAFeeToPay)}
																</Typography>
															</Box>
														)}
														{false && balance > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography>
																	{t(
																		"climecoCertificationProcess.currentBalance"
																	)}
																</Typography>
																<Typography textAlign={"right"}>
																	{Intl.NumberFormat("en", {
																		maximumFractionDigits: 2,
																		minimumFractionDigits: 2,
																		style: "currency",
																		currency: "usd",
																	}).format(-balance)}
																</Typography>
															</Box>
														)}
														{offsetsPercentage > 0 && (
															<Box
																sx={{
																	display: "flex",
																	marginTop: 1,
																	justifyContent: "space-between",
																	flexDirection: {
																		xs: "column",
																		sm: "row",
																	},
																}}
															>
																<Typography
																	sx={{
																		fontSize: 12,
																		fontStyle: "italic",
																		opacity: 0.5,
																	}}
																>
																	{t(
																		"certificationAgree.offsetsPercentageDesc"
																	)}
																</Typography>
															</Box>
														)}
													</Box>
												</Grid>
											</Grid>
										)}

										{!loadingDocuSignResult &&
										certification?.certification_process_steps[currentStep]
											?.step?.step_type === "PURCHASE_OFFSETS" &&
										(!certification?.manual_approval || !user?.is_superuser) ? (
											certification?.certification_process_steps[currentStep]
												.payment_status === "NOT_STARTED" ? (
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														padding: 4,
													}}
												>
													<Button
														variant="contained"
														onClick={() => {
															setShowPurchaseOffsets(true);
														}}
													>
														{t("climecoCertificationProcess.seeInvoice")}
													</Button>
												</Box>
											) : certification?.certification_process_steps[
													currentStep
											  ].payment_status === "PENDING" ? (
												user?.is_superuser ? (
													<Box>
														<Alert severity="info">
															{t("climecoCertificationProcess.payLaterInfo")}
															<br />
															<b>
																{t(
																	"climecoCertificationProcess.markAsPaidPrompt"
																)}
															</b>
														</Alert>
														<Button
															variant="contained"
															onClick={() => {
																setShowMarkItAsPaidModal(true);
															}}
															sx={{ marginTop: 2 }}
														>
															{t(
																"climecoCertificationProcess.markAsPaidButton"
															)}
														</Button>
													</Box>
												) : (
													<Box>
														<Alert severity="info">
															{t(
																"climecoCertificationProcess.paymentSubmittedInfo"
															)}
															<br />
															<b>
																{t(
																	"climecoCertificationProcess.emailNotification"
																)}
															</b>
														</Alert>
														<Button
															variant="contained"
															onClick={() => {
																setShowPurchaseOffsets(true);
															}}
															sx={{ marginRight: 2, marginTop: 2 }}
														>
															{t(
																"climecoCertificationProcess.seeInvoiceButton"
															)}
														</Button>
													</Box>
												)
											) : null
										) : null}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "PURCHASE_OFFSETS" &&
											certification?.manual_approval &&
											user?.is_superuser && (
												<>
													<Divider sx={{ marginBottom: 3 }} />
													<Typography variant="h6">
														{t(
															"climecoCertificationProcess.manuallyAddOffsetsTitle"
														)}
													</Typography>

													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															gap: 1,
															flex: 1,
															alignItems: "center",
															marginBottom: 2,
														}}
													>
														<span className={classes.inputLabel}>
															{t("climecoCertificationProcess.offsetsLabel")}
														</span>
														<NumericFormat
															value={manualOffsets}
															style={{
																border: "1px solid rgb(3, 176, 147)",
																borderRadius: 4,
																maxWidth: 200,
															}}
															name={"amount"}
															placeholder="Offsets"
															customInput={TextField}
															type="text"
															allowNegative={false}
															decimalScale={0}
															autoFocus={true}
															thousandSeparator={","}
															onValueChange={({ floatValue }) => {
																setManualOffsets(floatValue);
															}}
														/>
													</Box>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															gap: 1,
															flex: 1,
															alignItems: "center",
															marginBottom: 2,
														}}
													>
														<span className={classes.inputLabel}>
															{t("climecoCertificationProcess.pricePerOffset")}
														</span>
														<NumericFormat
															value={manualOffsetsPrice}
															placeholder="Price per Offset"
															customInput={TextField}
															onValueChange={({ value }) => {
																setManualOffsetsPrice(parseFloat(value));
															}}
															style={{
																border: "1px solid rgb(3, 176, 147)",
																borderRadius: 4,
																maxWidth: 200,
															}}
															allowNegative={false}
															decimalScale={2}
															thousandSeparator={","}
														/>
													</Box>
													<Button
														variant="contained"
														onClick={() => {
															mutateAddOffsets();
														}}
														sx={{
															marginTop: 4,
															alignSelf: "center",
															minHeight: 34,
														}}
														disabled={
															isLoading ||
															isLoadingMutateAddOffsets ||
															manualOffsets === undefined ||
															manualOffsets < 0
														}
													>
														{isLoading || isLoadingMutateAddOffsets ? (
															<CircularProgress
																size={12}
																sx={{ color: "white" }}
															/>
														) : (
															t("climecoCertificationProcess.saveManualOffsets")
														)}
													</Button>
												</>
											)}

										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "REDIRECTION" &&
											!certification?.manual_approval &&
											!user?.is_superuser &&
											(!isDocusignLinkGenerated ||
												certification?.climeco_contract_url_customer) && (
												<Box sx={{ marginTop: 4, marginBottom: 4 }}>
													<Typography sx={{ marginBottom: 2 }}>
														{t("climecoCertificationProcess.shareLinkInfo")}{" "}
														<b>{t("climecoCertificationProcess.expires")}</b>
													</Typography>
													<br />
													<Box sx={{ marginLeft: "30px" }}>
														{isDocusignLinkGenerated ? (
															<Button
																variant="contained"
																color="primary"
																disabled={isLoadingRefecthLink}
																href={
																	certification?.climeco_contract_url_customer ??
																	""
																}
																target="_blank"
																rel="noopener"
															>
																{t("climecoCertificationProcess.contractLink")}
															</Button>
														) : (
															<Button
																variant="contained"
																color="primary"
																disabled={isLoadingRefecthLink}
																onClick={() => {
																	refetchLink();
																}}
																sx={{
																	marginTop: 2,
																	maxWidth: 300,
																	minHeight: 34,
																}}
															>
																{isLoadingRefecthLink ? (
																	<CircularProgress
																		size={12}
																		style={{ color: "white" }}
																	/>
																) : (
																	t("climecoCertificationProcess.generateLink")
																)}
															</Button>
														)}
														{isDocusignLinkGenerated && (
															<IconButton
																onClick={async () => {
																	try {
																		await navigator.clipboard.writeText(
																			certification!
																				.climeco_contract_url_customer!
																		);
																		enqueueSnackbar(
																			t(
																				"climecoCertificationProcess.linkCopied"
																			),
																			{
																				variant: "success",
																			}
																		);
																	} catch (err) {
																		console.error("Failed to copy: ", err);
																		enqueueSnackbar(
																			t(
																				"climecoCertificationProcess.linkCopyFailed"
																			),
																			{
																				variant: "error",
																			}
																		);
																	}
																}}
																size="small"
																style={{ color: "inherit" }}
															>
																<ContentCopyIcon fontSize="small" />
															</IconButton>
														)}
													</Box>
												</Box>
											)}
										{!loadingDocuSignResult &&
											certification?.certification_process_steps[currentStep]
												?.step?.step_type === "REDIRECTION" &&
											certification?.manual_approval &&
											user?.is_superuser && (
												<>
													<Divider sx={{ marginBottom: 3 }} />
													<Typography variant="h6">
														{t(
															"climecoCertificationProcess.addContractDocumentsTitle"
														)}
													</Typography>
													<span className={classes.inputLabel}>
														{t(
															"climecoCertificationProcess.addContractDocuments"
														)}
													</span>
													<UploadContracts
														id={certification.id}
														companyId={certification.company.id}
														certificationProcessId={
															certification?.certification_process_steps[
																currentStep
															].id
														}
														callback={refetchCertification}
													/>
												</>
											)}
									</Box>
								</>
							)}
					</Box>
					{(certification?.certification_process_steps[currentStep]?.step
						?.step_type === "CERTIFICATION_SUMMARY" ||
						(user?.is_superuser &&
							(certification?.certification_process_steps[currentStep]?.step
								?.step_type === "REDIRECTION" ||
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "PURCHASE_OFFSETS" ||
								certification?.certification_process_steps[currentStep]?.step
									?.step_type === "OTHER"))) && (
						<Box
							sx={{
								backgroundColor: "#ffffff",
								paddingLeft: 4,
								paddingRight: 4,
								flexDirection: "row",
								borderTop: "1px solid #D7EBFF",
								position: "absolute",
								bottom: 0,
								left: 0,
								right: 0,
							}}
						>
							<Box
								sx={{
									display: "flex",
									paddingTop: 2,
									paddingBottom: 0,
									justifyContent: "space-between",
								}}
							>
								<Typography sx={{ fontWeight: 500 }}>
									{t("climecoCertificationProcess.totalPrice")}
								</Typography>
								<Typography sx={{ fontWeight: 700, color: "#34918E" }}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(
										Math.ceil(
											process.env.REACT_APP_ENV === "stage" ||
												process.env.REACT_APP_DO_NOT_CEIL_BALANCES === "TRUE"
												? (certification?.estimations
														.filter(
															(estimation) =>
																!certification.is_add_products ||
																(certification.is_add_products &&
																	estimation.is_add_products)
														)
														.reduce((prev, current) => {
															return (
																prev +
																(current.product_lca_version_emission
																	?.emissions ?? 0) *
																	(!isNaN(current.amount ?? 0) && current.amount
																		? current.amount
																		: 0)
															);
														}, 0) ?? 0) * 0.001
												: certification?.estimations
														.filter(
															(estimation) =>
																!certification.is_add_products ||
																(certification.is_add_products &&
																	estimation.is_add_products)
														)
														.reduce((prev, current) => {
															return (
																prev +
																Math.ceil(
																	(current.product_lca_version_emission
																		?.emissions ?? 0) *
																		(!isNaN(current.amount ?? 0) &&
																		current.amount
																			? current.amount
																			: 0) *
																		0.001
																)
															);
														}, 0) ?? 0
										) *
											(certification?.is_update_lca ? 0 : 1) *
											12.5 +
											annualFeeToPay +
											lcaFeeToPay +
											revisedLCAFeeToPay /*-
																		(balance > 0 ? balance : 0)*/ <
											5000 &&
											(!certification.company.first_time_fee_paid ||
												(certification.company.first_time_fee_paid &&
													new Date(
														certification.company.first_time_fee_paid
													).getTime() <
														new Date().getTime() - 365 * 24 * 60 * 60 * 1000))
											? 5000 + rejectionsLCAFeeToPay
											: totalOffsets *
													(certification?.is_update_lca ? 0 : 1) *
													12.5 +
													annualFeeToPay +
													lcaFeeToPay +
													revisedLCAFeeToPay +
													rejectionsLCAFeeToPay /*-
																				(balance > 0 ? balance : 0)*/
									)}
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
			)}

			<BasicModal
				width={800}
				showModal={
					!!(
						certification &&
						certification?.certification_process_steps &&
						certification?.certification_process_steps.length > 0 &&
						certification?.certification_process_steps[currentStep]?.step
							?.step_type === "CERTIFICATION_SUMMARY" &&
						!user?.is_superuser &&
						(certification?.lca_versions ?? []).find(
							(lcaVersion) => lcaVersion.rejected_date
						) === undefined &&
						certification?.requested_approval
					)
				}
				handleClose={() => {}}
				hideClose={true}
				children={
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							backgroundColor: "#ffffff",
						}}
					>
						<CertificationReview />
					</Box>
				}
			/>

			<BasicModal
				width={800}
				showModal={showAgreeModal}
				handleClose={() => {
					setShowAgreeModal(false);
				}}
				children={
					certification && (
						<CertificationAgree
							text={t("climecoCertificationProcess.agreeText")}
							button1={t("climecoCertificationProcess.cancelButton")}
							button1Callback={() => {
								setShowAgreeModal(false);
							}}
							button2={t("climecoCertificationProcess.agreeButton")}
							button2Callback={() => {
								mutateApproval();
							}}
							isLoading={isLoadingApproval}
							certification={certification}
							subscriptions={subscriptions}
							annualFeeToPay={annualFeeToPay}
							lcaFeeToPay={lcaFeeToPay}
							lcaFeeToPayMultiplier={lcaFeeToPayMultiplier}
							revisedLCAFeeToPay={revisedLCAFeeToPay}
							revisedLCAFeeToPayMultiplier={revisedLCAFeeToPayMultiplier}
							rejectionsLCAFeeToPay={rejectionsLCAFeeToPay}
							balance={balance}
							offsets={offsets}
							offsetsPercentage={offsetsPercentage}
							totalOffsets={totalOffsets}
						/>
					)
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showConfirmation}
				handleClose={() => {
					setShowConfirmation(false);
				}}
				children={
					<CertificationConfirmation
						confirmationData={confirmationData}
						handleModalClose={() => {
							setShowConfirmation(false);
						}}
						isLoading={
							isLoadingCreateEstimation ||
							isLoadingUpdateEstimation ||
							isLoading ||
							isLoadingDeleteEstimation ||
							isLoadingAdminApproval
						}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showMarkItAsPaidModal}
				handleClose={() => {
					setShowMarkItAsPaidModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("climecoCertificationProcess.markAsPaidConfirmation")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowMarkItAsPaidModal(false);
								}}
							>
								{t("climecoCertificationProcess.cancelButton")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={isLoadingMarkItAsPaid}
								onClick={() => {
									mutateMarkItAsPaid();
								}}
							>
								{isLoadingMarkItAsPaid ? (
									<CircularProgress size={12} style={{ color: "white" }} />
								) : (
									t("climecoCertificationProcess.markItAsPaid")
								)}
							</Button>
						</Box>
					</Box>
				}
			/>

			<BasicModal
				width={800}
				showModal={showTitleModal}
				handleClose={() => {
					setShowTitleModal(false);
				}}
				children={
					<Box>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{t("climecoCertificationProcess.productsList")}
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							{certification &&
								certification.estimations.map((est, index) => (
									<p key={est.id}>
										{est.product?.name}
										{(est.product?.asin || est.product?.upc) && (
											<>
												{" - "}
												{est.product?.asin || est.product?.upc || "--"}
											</>
										)}
									</p>
								))}
						</Typography>
					</Box>
				}
			/>
			<BasicModal
				width={800}
				showModal={showAmazonModal}
				handleClose={() => {
					setShowAmazonModal(false);
				}}
				children={
					<CertificationComplete
						setShowAmazonModal={setShowAmazonModal}
						certification={certification ?? undefined}
					/>
				}
			/>
			<BasicModal
				width={600}
				showModal={showPurchaseOffsets}
				handleClose={() => {
					setShowPurchaseOffsets(false);
				}}
				hideClose={false}
				children={
					certification?.estimations && (
						<PurchaseOffsets
							quantity={
								(5000 -
									annualFeeToPay -
									lcaFeeToPay -
									revisedLCAFeeToPay /*-
									+(balance > 0 ? balance : 0)*/ -
									totalOffsets *
										(certification?.is_update_lca ? 0 : 1) *
										12.5) /
									12.5 >
									0 &&
								(!certification.company.first_time_fee_paid ||
									(certification.company.first_time_fee_paid &&
										new Date(
											certification.company.first_time_fee_paid
										).getTime() <
											new Date().getTime() - 365 * 24 * 60 * 60 * 1000))
									? (5000 -
											(annualFeeToPay + lcaFeeToPay + revisedLCAFeeToPay)) /*-
											(balance > 0 ? balance : 0)*/ /
									  12.5
									: totalOffsets * (certification?.is_update_lca ? 0 : 1)
							}
							productsCount={
								certification.estimations.filter(
									(estimation) =>
										!certification.is_add_products ||
										(certification.is_add_products &&
											estimation.is_add_products)
								).length
							}
							needsFirstTimeFee={
								certification.company.first_time_fee_paid === null
							}
							certificationProcessId={certification.id}
							callback={() => {
								/*enqueueSnackbar("Successful payment! Moving to next step...", {
									variant: "success",
								});*/

								setTimeout(() => {
									refetchCertification();
								}, 1000);
							}}
							height={"auto"}
							inModal={true}
							closeModal={() => {
								setShowPurchaseOffsets(false);
							}}
							offsetsLoadingMessages={offsetsLoadingMessages}
							messagesIndex={messagesIndex}
							setLoadingOffsetsResult={setLoadingOffsetsResult}
							lcaIds={(certification.lca_versions ?? [])
								.filter((l) => l.lca_id)
								.map((l) => l.lca_id!)}
							lcaVersionIds={(certification.lca_versions ?? []).map(
								(l) => l.id
							)}
						/>
					)
				}
			/>
			<BasicModal
				width={800}
				showModal={showNewLCAVersionModal}
				handleClose={() => {
					setShowNewLCAVersionModal(false);
				}}
				hideClose={false}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("climecoCertificationProcess.newVersionPrompt")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowNewLCAVersionModal(false);
								}}
							>
								{t("climecoCertificationProcess.no")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={false}
								onClick={() => {
									window.location.href =
										window.location.origin +
										"/climeco-certificationss/" +
										LCA!.lca_versions![0].certification_process!.id;
								}}
							>
								{t("climecoCertificationProcess.yes")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
