import { createTheme } from '@mui/material/styles';

const productsTheme = createTheme({
    typography: {
        fontFamily: 'Inter, Arial',
        h4: {
            fontSize: 20,
        },
    },
    palette: {
        primary: {
            main: "#34918E"
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    borderRadius: "80px",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    "&:not(.MuiButton-outlined):disabled": {
                        backgroundColor: "#34918E",
                        opacity: 0.4,
                        color: "#fff"
                    }
                },

            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    border: "1px solid #C9C9C9", borderRadius: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    "& .MuiAutocomplete-clearIndicator": {

                    }
                },
                inputRoot: {
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    "& .MuiAutocomplete-tag": {
                        marginRight: 8,
                    },
                },
                input: {
                    border: "none!important",
                    padding: "0px 4px 0px 4px!important"
                },
                tag: {
                    height: 24,
                    "& .MuiChip-label": {
                        padding: "0 8px",
                        fontSize: 12,
                    },
                    "& .MuiChip-deleteIcon": {
                        marginLeft: 0,
                    },
                },
                clearIndicator: {
                    display: "none!important"
                },
                popupIndicator: {
                    background: "white!important"
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    '& fieldset:not(".Mui-disabled")': { border: "1px solid #C9C9C9", borderRadius: "8px", height: "60px", padding: "11.5px 14px" },
                    "& input, & textarea": { border: "1px solid #C9C9C9", borderRadius: "8px", padding: "11.5px 14px" },
                    marginRight: 2,

                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: "#fafafa",
                    '&:focus': {
                        backgroundColor: "#fafafa",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                },
                icon: {
                    color: "rgba(0, 0, 0, 0.54)",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        backgroundColor: "#fafafa",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    "& fieldset": {
                        border: "none",
                    },
                    "& .Mui-disabled": {
                        border: "1px solid #C9C9C9",
                    },
                    color: "rgba(0, 0, 0, 0.87)",
                },
                notchedOutline: {
                    border: "none",
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    color: "rgba(0, 0, 0, 0.54)",
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: 14
                }
            },
        }
    },
});

export default productsTheme