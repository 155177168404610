import { IOffset } from "../../models";
import { connectionsClient } from "../../utils/ConnectionsClient"
import { OffsetTypes } from "./offset.types";

class Offset {
    async create({ quantity, productsCount, firstTimeFeePaid, certificationProcessId, lcaIds, lcaVersionIds, successURL, cancelURL, isForForecasted2025Sales }: OffsetTypes.Create): Promise<any> {
        const endpoint = "climeco-offsets-order"
        const response = await connectionsClient.client.post(endpoint, { quantity, products_count: productsCount, needs_first_time_fee: firstTimeFeePaid, certification_process_id: certificationProcessId, lca_ids: lcaIds, lca_version_ids: lcaVersionIds, success_url: successURL, cancel_url: cancelURL, is_for_forecasted_2025: isForForecasted2025Sales });
        return response.data;
    }

    async companyList(companyId: number): Promise<Array<IOffset>> {
        const endpoint = "companies/" + companyId + "/offsets/"
        const response = await connectionsClient.client.get(endpoint);
        return response.data.results;
    }
}

export const offset = new Offset();