
import { IForm, IQuestion, IUser } from "../../models";
import { IResponse } from "../../models/response-model";
import { connectionsClient } from "../../utils/ConnectionsClient";

class Form {
    async list(companyId?: number, search?: string, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<IForm> }> {
        const endpoint = companyId ? "companies/" + companyId + "/forms" : "forms"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", page_size: pageSize, company__is_test_company: localStorage.getItem("showTestData") ? undefined : false },
            signal
        });
        return response.data;
    }

    async listForChild(companyId?: number, search?: string, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<IForm> }> {
        const endpoint = companyId ? "companies/" + companyId + "/child-forms" : "child-forms"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", page_size: pageSize, company__is_test_company: localStorage.getItem("showTestData") ? undefined : false },
            signal
        });
        return response.data;
    }

    async getOne(formId: number, companyId?: number): Promise<IForm> {
        const endpoint = companyId ? "companies/" + companyId + "/forms/" + formId : "forms/" + formId
        console.log(endpoint)
        const response = await connectionsClient.client.get(endpoint);
        console.log(response)
        return response.data;
    }

    async create(title: string, description: string, status: string, companyId?: number): Promise<IForm> {
        const endpoint = companyId ? "companies/" + companyId + "/forms" : "forms";

        const response = await connectionsClient.client.post(endpoint, {
            title: title, description: description, status: status
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async update(id: number, title: string, description: string, status: string, companyId?: number): Promise<IForm> {
        const endpoint = companyId ? "companies/" + companyId + "/forms/" + id : "forms/" + id

        const response = await connectionsClient.client.patch(endpoint, {
            title: title, description: description, status: status
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async addQuestion(companyId: number, formId: number, questionData: any): Promise<void> {
        const endpoint = `companies/${companyId}/forms/${formId}/questions/`;
        await connectionsClient.client.post(endpoint, questionData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    async updateQuestion(questionId: number, companyId: number, formId: number, questionData: any): Promise<void> {
        const endpoint = `companies/${companyId}/forms/${formId}/questions/${questionId}`;
        await connectionsClient.client.patch(endpoint, questionData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    async deleteQuestion(questionId: number, companyId: number, formId: number): Promise<void> {
        const endpoint = `companies/${companyId}/forms/${formId}/questions/${questionId}`;
        await connectionsClient.client.delete(endpoint, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }


    async getQuestions(companyId: number, formId: number): Promise<IQuestion[]> {
        const endpoint = `companies/${companyId}/forms/${formId}/questions/`;
        const response = await connectionsClient.client.get(endpoint, { params: { ordering: "order" } });
        return response.data.results || [];
    }

    async getUserResponses(companyId: number, formId: number): Promise<IResponse[]> {
        const endpoint = `companies/${companyId}/forms/${formId}/responses/`;
        const response = await connectionsClient.client.get(endpoint);
        return response.data.results || [];
    }

    async saveResponse(companyId: number, formId: number, questionId: number, responseId: number, text_value: string | null, json_value: Array<number> | null, number_value: number, date_value: string | null, file_value: string | File | null): Promise<IResponse[]> {
        const formData = new FormData();
        if (text_value) {
            formData.append("text_value", text_value!);
        }
        formData.append("json_value", JSON.stringify(json_value!));
        if (number_value) {
            formData.append("number_value", number_value.toString());
        }
        if (date_value) {
            formData.append("date_value", date_value)
        }
        if (file_value) {
            formData.append("file_value", file_value)
        }

        console.log("RESPONSE ID")
        console.log(responseId)

        if (responseId > 0) {
            const endpoint = `companies/${companyId}/forms/${formId}/responses/${responseId}`;
            const response = await connectionsClient.client.patch(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
            return response.data.results || [];
        }
        else {
            const endpoint = `companies/${companyId}/forms/${formId}/questions/${questionId}/responses/`;
            const response = await connectionsClient.client.post(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
            return response.data.results || [];
        }
    }

    async getDashboardFormStats(companyId: number): Promise<{ forms: number, started_forms: number, completed_forms: number }> {
        const endpoint = `companies/${companyId}/dashboard-form-stats/`;
        const response = await connectionsClient.client.get(endpoint);
        return response.data || { forms: 0, started_forms: 0, completed_forms: 0 };
    }

    async getFormStats(companyId: number, formId: number): Promise<{ users_started_forms: number, responses: Object }> {
        const endpoint = `companies/${companyId}/form-stats/${formId}`;
        const response = await connectionsClient.client.get(endpoint);
        return response.data || { users_started_forms: 0, responses: {} };
    }

    async getFormResponses(companyId: number, formId: number): Promise<Array<IUser>> {
        const endpoint = `companies/${companyId}/form-responses/${formId}`;
        const response = await connectionsClient.client.get(endpoint);
        return response.data.results;
    }
}

export const form = new Form();