import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Divider, Box, IconButton } from "@mui/material";
import Logo from "../../assets/logo-vertical.png";
import { User, MenuItem } from "../../components";
import { PropTypes } from "./SideBar.types";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import { UserContext } from "../../contexts/user";
import {
	TbLayoutDashboard as DashboardIcon,
	TbClipboardText as ProductIcon,
	TbCertificate as CertificateIcon,
	TbReportAnalytics as ReportIcon,
	TbUserSquareRounded as UserIcon,
	TbBuildingCommunity as CompanyIcon,
	TbFileDescription as DocumentIcon,
	TbListDetails as LCAIcon,
	TbSettings as SettingsIcon,
	TbCloudUpload as UploadIcon,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import { DisplayContext } from "../../contexts/display";

const drawerWidth = 280;

const useStyles = makeStyles({
	logo: {
		height: "auto",
		width: 70,
		objectFit: "contain",
	},
});

export function SideBar({ mobileOpen, handleDrawerToggle }: PropTypes) {
	const { user } = useContext(UserContext);
	const location = useLocation();
	const [pathname, setPathname] = useState(location.pathname);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { displayMode } = useContext(DisplayContext);

	useEffect(() => {
		setPathname(location.pathname);
	}, [location.pathname]);

	const classes = useStyles();

	const commonPages = [
		{
			name: t("sidebar.home"),
			icon: (
				<DashboardIcon
					color={pathname === "/" ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></DashboardIcon>
			),
			link: "/",
			id: "dashboard",
		},
		{
			name: t("sidebar.myAccount"),
			icon: (
				<SettingsIcon
					color={pathname.includes("/profile") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></SettingsIcon>
			),
			link: "/profile/",
			id: "account",
		},
	];
	const supplierPages = !user?.company?.is_parent_company
		? []
		: [
				{
					name: t("sidebar.forms"),
					icon: <CompanyIcon style={{ marginRight: 8, minWidth: 16 }} />,
					link: "/forms/",
					id: "form",
				},
				/*{
			name: t("sidebar.formdisplay"),
			icon: <CompanyIcon style={{ marginRight: 8, minWidth: 16 }} />,
			link: "/form-display/10",
			id: "form-display",
		},
		{
			name: t("sidebar.formstats"),
			icon: <CompanyIcon style={{ marginRight: 8, minWidth: 16 }} />,
			link: "/form-stats/",
			id: "form-stats",
		},*/
		  ];
	const certificationPages = [
		{
			name: t("sidebar.lcaLibrary"),
			icon: (
				<LCAIcon
					color={pathname.includes("/lca") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></LCAIcon>
			),
			link: "/lcas/",
			id: "lcas",
		},
		{
			name: t("sidebar.products"),
			icon: (
				<ProductIcon
					color={pathname.includes("/product") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></ProductIcon>
			),
			link: "/products/",
			id: "products",
		},
		{
			name: t("sidebar.salesReport"),
			icon: (
				<ReportIcon
					color={pathname.includes("/sale") ? "#25406D" : "inherit"}
					style={{ marginRight: 8, minWidth: 16 }}
				></ReportIcon>
			),
			link: "/sales/",
			id: "sales",
		},
	];

	const pages =
		displayMode === "SUPPLIER"
			? [...commonPages, ...supplierPages]
			: [
					...commonPages,
					...[
						{
							name: t("sidebar.users"),
							icon: (
								<UserIcon
									color={pathname.includes("/user") ? "#25406D" : "inherit"}
									style={{ marginRight: 8, minWidth: 16 }}
								></UserIcon>
							),
							link: "/users/",
							id: "users",
						},
					],
					...certificationPages,
			  ];

	if (displayMode !== "SUPPLIER") {
		if (user?.is_superuser) {
			pages.push({
				name: t("sidebar.documents"),
				icon: (
					<DocumentIcon
						color={pathname.includes("/document") ? "#25406D" : "inherit"}
						style={{ marginRight: 8, minWidth: 16 }}
					></DocumentIcon>
				),
				link: "/documents/",
				id: "documents",
			});

			pages.splice(
				3,
				0,
				{
					name: t("sidebar.climecoCertifications"),
					icon: (
						<CertificateIcon
							color={
								pathname.includes("/climeco-certifications") ||
								pathname.includes("/climeco-certificationss")
									? "#25406D"
									: "inherit"
							}
							style={{ marginRight: 8, minWidth: 16 }}
						></CertificateIcon>
					),
					link: "/climeco-certifications/",
					id: "climeco-certifications",
				},
				{
					name: t("sidebar.externalCertifications"),
					icon: (
						<CertificateIcon
							color={
								pathname.includes("/certification") ? "#25406D" : "inherit"
							}
							style={{ marginRight: 8, minWidth: 16 }}
						></CertificateIcon>
					),
					link: "/certifications/",
					id: "certifications",
				}
			);

			pages.push({
				name: t("sidebar.companies"),
				icon: (
					<CompanyIcon
						color={pathname.includes("/compan") ? "#25406D" : "inherit"}
						style={{ marginRight: 8, minWidth: 16 }}
					></CompanyIcon>
				),
				link: "/companies/",
				id: "companies",
			});
		}
		if (!user?.is_superuser && user?.company?.is_parent_company) {
			pages.push({
				name: t("sidebar.companies"),
				icon: (
					<CompanyIcon
						color={pathname.includes("/compan") ? "#25406D" : "inherit"}
						style={{ marginRight: 8, minWidth: 16 }}
					></CompanyIcon>
				),
				link: "/companies/",
				id: "companies",
			});
		}
		if (user?.is_superuser) {
			pages.push({
				name: t("sidebar.bulkUploads"),
				icon: (
					<UploadIcon
						color={pathname.includes("/bulk-uploads") ? "#25406D" : "inherit"}
						style={{ marginRight: 8, minWidth: 16 }}
					></UploadIcon>
				),
				link: "/bulk-uploads/",
				id: "bulk-uploads",
			});
		}
	} else {
		if (!user?.is_superuser && user?.company?.is_parent_company) {
			pages.push({
				name: t("sidebar.companies"),
				icon: (
					<CompanyIcon
						color={pathname.includes("/compan") ? "#25406D" : "inherit"}
						style={{ marginRight: 8, minWidth: 16 }}
					></CompanyIcon>
				),
				link: "/companies/",
				id: "companies",
			});
		}
	}
	const drawer = (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				padding: 2,
				textAlign: "center",
				height: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "flex-start",
					}}
				>
					<img
						src={Logo}
						alt="climeco"
						className={classes.logo}
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/")}
					/>
					<IconButton
						aria-label="menu"
						sx={{
							alignSelf: "flex-start",
							fontSize: 18,
							padding: 0,
							background: "white",
						}}
						onClick={() => handleDrawerToggle()}
					>
						<MenuIcon />
					</IconButton>
				</Box>
				<User name={user?.username ?? ""} company={user?.company?.name ?? ""} />
				<Divider sx={{ marginTop: 4, marginBottom: 2, opacity: 0.3 }} />
				<Box>
					{pages.map((page) => {
						return (
							<MenuItem
								key={page.name}
								title={page.name}
								icon={page.icon}
								link={page.link}
								selected={
									pathname === page.link ||
									pathname + "s" === page.link ||
									pathname === page.link.slice(0, page.link.length - 4) ||
									((pathname.includes(page.link) ||
										pathname.includes(page.link.slice(0, -4))) &&
										page.link !== "/" &&
										page.link !== "")
								}
								callback={() => {
									if (document.body.clientWidth <= 900) {
										handleDrawerToggle();
									}
								}}
							/>
						);
					})}
				</Box>
			</Box>
			<Box sx={{ marginBottom: "70px" }}>
				<LanguageSelector />
			</Box>
		</Box>
	);

	return (
		<>
			<Box
				component="nav"
				sx={{
					width: {
						md: mobileOpen ? drawerWidth : 0,
					},
					flexShrink: { md: 0 },
				}}
			>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "block", sm: "block", md: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="persistent"
					PaperProps={{
						sx: {
							backgroundColor: "#fff",
							border: "none",
							borderRight: "1px solid #ececec",
						},
					}}
					sx={{
						display: { xs: "none", sm: "none", md: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open={mobileOpen}
				>
					{drawer}
				</Drawer>
			</Box>
		</>
	);
}
