import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	Button,
	FormControl,
	FormLabel,
	Rating,
	Modal,
	IconButton,
	CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/logo-vertical.png";
import { makeStyles } from "@mui/styles";
import { keyframes } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { form as formActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { IResponse } from "../../models/response-model";
import { useSnackbar } from "notistack";
import { IForm, IQuestion } from "../../models";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const useStyles = makeStyles({
	logo: {
		height: "auto",
		width: 70,
		objectFit: "contain",
	},
});

export const FormDisplay: React.FC = () => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const [form, setForm] = useState<IForm | null>(null);
	const [questions, setQuestions] = useState<Array<IQuestion>>([]);
	const [responses, setResponses] = useState<Array<IResponse> | null>(null);
	const navigate = useNavigate();

	const { id: formId, companyId } = useParams(); // Lee el ID del formulario desde la URL
	const { refetch: getForm } = useQuery(
		["form", formId],
		() => formActions.getOne(Number(formId), Number(companyId)),
		{
			enabled: false,
			onSuccess: (data) => {
				setForm(data);
			},
		}
	);
	const { refetch: getQuestions } = useQuery(
		["questions", formId],
		() => formActions.getQuestions(Number(companyId), Number(formId)),
		{
			enabled: false,
			onSuccess: (data) => {
				setQuestions(data);
			},
		}
	);
	const { refetch: getUserResponses } = useQuery(
		["responses", formId],
		() => formActions.getUserResponses(Number(companyId), Number(formId)),
		{
			enabled: false,
			onSuccess(data) {
				setResponses(
					data.sort((a, b) => {
						return (
							questions.findIndex((q) => q.id === a.question?.id) -
							questions.findIndex((q) => q.id === b.question?.id)
						);
					})
				);
				if (!responses && data.length > 0) {
					setCurrentIndex(data.length);
				}
				console.log(data);
			},
		}
	);

	const { mutate: mutateResponse, isLoading: isLoadingMutate } = useMutation(
		["reset"],
		({ response, questionId }: { response: IResponse; questionId: number }) => {
			console.log(response);
			return formActions.saveResponse(
				Number(companyId),
				Number(formId),
				questionId,
				response.id,
				response.text_value,
				response.json_value,
				response.number_value,
				response.date_value,
				response.file_value
			);
		},
		{
			onSuccess: async (res) => {
				await getUserResponses();

				if (currentIndex < questions.length) {
					setCurrentIndex((prev) => prev + 1);
				} else {
					handleClose();
				}
			},
			onError: (error) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (formId) {
			getForm();
		}
	}, [formId, getForm]);

	useEffect(() => {
		if (form) {
			getQuestions();
		}
	}, [form, getQuestions]);

	useEffect(() => {
		if (questions.length > 0 && !responses) {
			getUserResponses();
		}
	}, [questions, getUserResponses, responses]);

	const [open, setOpen] = useState(true);

	const [currentIndex, setCurrentIndex] = useState(-1); // -1 para la pantalla de inicio
	const [isAnimating] = useState(true);

	const currentQuestion = questions[currentIndex];

	const handleResponseChange = (value: any) => {
		setResponses((prev) => {
			let auxResponses = [...prev!];
			auxResponses[currentIndex] = value;
			return auxResponses;
		});
	};

	const handleNext = () => {
		if (currentIndex === -1) {
			setCurrentIndex((prev) => prev + 1);
		} else if (currentIndex > questions.length - 1) {
			setCurrentIndex((prev) => prev + 1);
		} else {
			console.log(responses);
			mutateResponse({
				response: (responses ?? [])[currentIndex],
				questionId: questions[currentIndex].id,
			});
		}
	};

	useEffect(() => {
		if (currentIndex > -1) {
			setResponses((responses) => {
				let auxResponses = responses ? [...responses] : null;
				if (!auxResponses) {
					auxResponses = Array<IResponse>();
				}
				console.log(currentIndex);
				console.log((auxResponses ?? []).length);
				if (
					currentIndex >= (auxResponses ?? []).length ||
					(currentIndex === 0 && (auxResponses ?? []).length === 0)
				) {
					auxResponses.push({
						id: -1 * new Date().getTime(),
						text_value: null,
						json_value: [],
						number_value: 0,
						date_value: null,
						file_value: null,
						question: questions[currentIndex],
						user: user,
					});
				}

				console.log("RESPONSES!");
				console.log(auxResponses);

				return auxResponses;
			});
		}
	}, [currentIndex, questions, user]);

	const handleClose = () => {
		setOpen(false);
		navigate("/");
	};

	const handleBack = () => {
		setCurrentIndex((prev) => prev - 1);
	};

	const classes = useStyles();

	const isNextButtonDisabled = () => {
		let disabled = false;
		if (
			currentIndex < (responses ?? []).length &&
			questions &&
			currentIndex < questions.length &&
			currentIndex > -1
		) {
			switch (questions[currentIndex].type) {
				case "TEXT":
					disabled =
						((responses ?? [])[currentIndex].text_value ?? "").trim().length ===
						0;
					break;
				case "DATETIME":
					disabled =
						((responses ?? [])[currentIndex].date_value ?? "").trim().length ===
						0;
					break;
				case "MULTIPLE_CHOICE":
					disabled =
						((responses ?? [])[currentIndex].json_value ?? Array<number>())
							.length === 0;
					break;
				case "SINGLE_CHOICE":
				case "RATING":
					disabled =
						isNaN((responses ?? [])[currentIndex].number_value) === true;
					break;
				case "FILE":
					disabled = !(responses ?? [])[currentIndex].file_value;
					break;
			}
		}

		return disabled;
	};

	return (
		<Modal open={open} onClose={handleClose} sx={{ overflow: "hidden" }}>
			<Box
				sx={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					backgroundColor: "#FFFFFF",
					position: "relative",
				}}
			>
				{/* Header */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						padding: "16px 24px",
						borderBottom: "1px solid #ccc",
						position: "absolute",
						top: 0,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flex: 1,
						}}
					>
						<Box sx={{ display: "flex", gap: 2 }}>
							<img src={Logo} alt="climeco" className={classes.logo} />
							<Typography variant="body2" sx={{ fontSize: 16 }}>
								<span style={{ fontWeight: "bold" }}>Supplier Portal </span>|{" "}
								{form?.title}
							</Typography>
						</Box>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: 4,
					}}
				>
					{currentIndex === -1 && (
						<Box
							sx={{
								backgroundColor: "white",
								borderRadius: 4,
								border: "1px solid #B1D1F0",
								width: "50%",
								textAlign: "center",
								padding: 4,
							}}
						>
							<Typography variant="h5" sx={{ marginBottom: 2 }}>
								{form?.title}
							</Typography>
							<Typography variant="body1" sx={{ marginBottom: 4 }}>
								{form?.description}
							</Typography>
						</Box>
					)}

					{currentIndex >= 0 && currentIndex < questions.length && (
						<Box
							sx={{
								backgroundColor: "white",
								borderRadius: 4,
								border: "1px solid #B1D1F0",
								width: "50%",
								textAlign: "center",
							}}
						>
							<Box sx={{ borderBottom: "1px solid #B1D1F0" }}>
								<Typography variant="h6" sx={{ padding: 2 }}>
									{`${currentIndex + 1}. ${currentQuestion?.title}`}
								</Typography>
							</Box>
							<Box sx={{ padding: 4 }}>
								{currentQuestion?.type === "TEXT" && (
									<TextField
										fullWidth
										placeholder={t("form.enterAnswer")}
										value={(responses ?? [])[currentIndex]?.text_value ?? ""}
										onChange={(e) => {
											let auxResponses = [...(responses ?? [])];
											auxResponses[currentIndex].text_value = e.target.value;

											handleResponseChange(auxResponses[currentIndex]);
										}}
										multiline
										rows={3}
									/>
								)}

								{/* Date Input */}
								{currentQuestion?.type === "DATETIME" && (
									<TextField
										fullWidth
										type="date"
										value={(responses ?? [])[currentIndex]?.date_value ?? ""}
										onChange={(e) => {
											let auxResponses = [...(responses ?? [])];
											auxResponses[currentIndex].date_value = e.target.value;

											handleResponseChange(auxResponses[currentIndex]);
										}}
									/>
								)}

								{/* Rating Input */}
								{currentQuestion?.type === "RATING" && (
									<Rating
										name={`rating-${currentQuestion.id}`}
										value={(responses ?? [])[currentIndex]?.number_value ?? 0}
										onChange={(_, newValue) => {
											let auxResponses = [...(responses ?? [])];
											console.log(auxResponses);
											auxResponses[currentIndex].number_value = newValue || 0;

											console.log(auxResponses[currentIndex]);

											handleResponseChange(auxResponses[currentIndex]);
										}}
									/>
								)}

								{/* Multiple Choice Input */}
								{(currentQuestion?.type === "MULTIPLE_CHOICE" ||
									currentQuestion?.type === "SINGLE_CHOICE") && (
									<FormControl>
										<FormLabel sx={{ marginBottom: 2 }}>
											{currentQuestion?.type === "MULTIPLE_CHOICE"
												? t("form.selectMultipleOptions")
												: t("form.selectSingleOption")}
										</FormLabel>
										{currentQuestion?.type === "MULTIPLE_CHOICE" ? (
											<>
												{currentQuestion.choices?.map((choice, i) => (
													<FormControlLabel
														key={i}
														control={
															<Checkbox
																checked={(() => {
																	return (
																		(responses ?? [])[currentIndex]
																			?.json_value ?? []
																	).find((v) => {
																		return v === choice.id;
																	})
																		? true
																		: false;
																})()}
																onChange={(e) => {
																	const auxResponses = responses ?? [];
																	if (e.target.checked) {
																		auxResponses[currentIndex].json_value?.push(
																			choice.id
																		);
																	} else {
																		auxResponses[currentIndex].json_value = (
																			auxResponses[currentIndex].json_value ??
																			[]
																		).filter((c) => c !== choice.id);
																	}

																	handleResponseChange(
																		auxResponses[currentIndex]
																	);
																}}
															/>
														}
														label={choice.text}
													/>
												))}
											</>
										) : (
											<RadioGroup
												value={
													(responses ?? [])[currentIndex]?.number_value ?? -1
												}
												onChange={(e) => {
													const auxResponses = responses ?? [];
													auxResponses[currentIndex].number_value = parseInt(
														e.target.value
													);

													handleResponseChange(auxResponses[currentIndex]);
												}}
											>
												{currentQuestion.choices?.map((choice, i) => (
													<FormControlLabel
														key={i}
														value={choice.id}
														control={<Radio />}
														label={choice.text}
													/>
												))}
											</RadioGroup>
										)}
									</FormControl>
								)}

								{/* File Upload Input */}
								{currentQuestion.type === "FILE" && (
									<Box>
										<Button variant="outlined" component="label">
											{t("form.uploadFileButton")}
											<input
												type="file"
												hidden
												onChange={(e) => {
													if (e.target.files && e.target.files.length > 0) {
														const file = e.target.files[0];
														handleResponseChange(file);
													}
												}}
											/>
										</Button>
										{(responses ?? [])[currentIndex] && (
											<Box sx={{ marginTop: 2 }}>
												<Typography variant="body2" color="textPrimary">
													{t("form.uploadedFile")}:{" "}
													{(responses ?? [])[
														currentIndex
													]?.file_value?.toString()}
												</Typography>
											</Box>
										)}
									</Box>
								)}
							</Box>
						</Box>
					)}

					{currentIndex === questions.length && (
						<Box
							sx={{
								backgroundColor: "white",
								borderRadius: 4,
								border: "1px solid #B1D1F0",
								width: "50%",
								textAlign: "center",
								padding: 4,
							}}
						>
							<Typography variant="h5" sx={{ marginBottom: 2 }}>
								{t("form.finishedTitle") || "Well done!"}
							</Typography>
							<Typography variant="body1" sx={{ marginBottom: 4 }}>
								{t("form.finishedMessage") || "You have completed the form!"}
							</Typography>
						</Box>
					)}
				</Box>

				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						padding: 2,
						background: "#FFFFFF",
						borderTop: "1px solid #EAEBEB",
						fontSize: "16px",
					}}
				>
					<Button
						variant="outlined"
						onClick={handleBack}
						disabled={currentIndex === -1}
						sx={{
							position: "relative",
							color: currentIndex === -1 ? "white" : "black",
							fontSize: "16px",
							paddingLeft: "26px",
							paddingRight: "26px",
						}}
					>
						{t("form.back")}
					</Button>

					{currentIndex < questions.length - 1 ? (
						<Button
							variant="contained"
							onClick={handleNext}
							sx={{
								position: "relative",
								color: "white",
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
								animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
								animationIterationCount: 3,
							}}
							disabled={isNextButtonDisabled()}
						>
							{isLoadingMutate ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : (
								t("form.next")
							)}
						</Button>
					) : currentIndex === questions.length ? (
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={() => {
								setOpen(false);
								navigate("/");
							}}
							sx={{
								position: "relative",
								color: "white",
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
								animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
								animationIterationCount: 3,
							}}
						>
							{isLoadingMutate ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : (
								t("form.closeFormButton")
							)}
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							color="primary"
							onClick={handleNext}
							sx={{
								position: "relative",
								color: "white",
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
								animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
								animationIterationCount: 3,
							}}
						>
							{isLoadingMutate ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : (
								t("form.submit")
							)}
						</Button>
					)}
				</Box>
			</Box>
		</Modal>
	);
};
