import { useContext, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "./BulkUploadActionBar.types";
import { BasicModal } from "../BasicModal";
import { bulkUpload as bulkUploadActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";

export function BulkUploadActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const {
		isLoading: isLoadingDeleteBulkUpload,
		mutate: mutateDeleteBulkUpload,
	} = useMutation(
		"delete-bulk-upload",
		() => bulkUploadActions.delete(selectedRows.map((row) => row.id)),
		{
			retry: false,
			onSuccess: () => {
				enqueueSnackbar(t("bulkUploadActionBar.deleteSuccess"), {
					variant: "success",
				});
				setShowDeleteModal(false);
				refetch();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					flex: 1,
				}}
			>
				{user?.is_superuser && selectedRows && selectedRows.length ? (
					<Button
						variant="outlined"
						sx={{ marginRight: 2, minHeight: 34, minWidth: 120 }}
						onClick={() => {
							setShowDeleteModal(true);
						}}
					>
						{t("bulkUploadActionBar.delete")}
					</Button>
				) : null}
				<Button
					variant="outlined"
					sx={{ marginRight: 2, minHeight: 34, minWidth: 120 }}
					onClick={() => {
						navigate("/bulk-uploads/create");
					}}
				>
					{t("bulkUploadActionBar.create")}
				</Button>
			</Box>
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={() => {
					setShowDeleteModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{selectedRows.length > 1
								? t("bulkUploadActionBar.deleteConfirmationMultiple")
								: t("bulkUploadActionBar.deleteConfirmationSingle")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowDeleteModal(false);
								}}
								disabled={isLoadingDeleteBulkUpload}
							>
								{t("bulkUploadActionBar.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									mutateDeleteBulkUpload();
								}}
								disabled={isLoadingDeleteBulkUpload}
							>
								{isLoadingDeleteBulkUpload ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("bulkUploadActionBar.delete")
								)}
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
