import { IEstimation } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { EstimateTypes } from "./estimation.types";

class Estimation {
    async list(certificationProcess: number, companyId?: number): Promise<Array<IEstimation>> {
        const endpoint = companyId ? "companies/" + companyId + "/climeco-certification-processes/" + certificationProcess + "/estimations" : "/climeco-certification-processes/" + certificationProcess + "/estimations"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, ordering: "-created_at" }
        });
        return response.data.results;
    }

    async create({ companyId, amount, product, certificationProcess, start_date, end_date, overwrite_certification_date, product_lca_version_emission_id, is_add_products, is_update_lca }: EstimateTypes.Create): Promise<IEstimation> {
        const endpoint = companyId ? "companies/" + companyId + "/climeco-certification-processes/" + certificationProcess + "/estimations" : "/climeco-certification-processes/" + certificationProcess + "/estimations"
        const response = await connectionsClient.client.post(endpoint, { amount, product, certification_process: certificationProcess, start_date, end_date, overwrite_certification_date, product_lca_version_emission_id, is_add_products, is_update_lca });
        return response.data;
    }

    async update({ id, companyId, amount, certificationProcess }: EstimateTypes.Update): Promise<IEstimation> {
        const formData = new FormData();
        formData.append("amount", amount!.toString());

        const endpoint = companyId ? "companies/" + companyId + "/climeco-certification-processes/" + certificationProcess + "/estimations/" + id : "/climeco-certification-processes/" + certificationProcess + "/estimations/" + id
        const response = await connectionsClient.client.patch(endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    }

    async update2025ForecastedSales({ id, companyId, forecastedSales, forecastedSalesId }: EstimateTypes.UpdateForecasted): Promise<IEstimation> {
        const formData = new FormData();
        formData.append("forecasted_2025_emissions", forecastedSales!.toString());

        if (forecastedSalesId) {
            formData.append("forecasted_2025_emissions_id", forecastedSalesId!.toString());
        }
        const endpoint = "companies/" + companyId + "/estimations/" + id
        const response = await connectionsClient.client.patch(endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    }

    async toggle({ id, certificationProcessId, companyId, toggle, reject_reason, overwrite_certification_date, pcf_amount }: EstimateTypes.Toggle): Promise<IEstimation> {
        const endpoint = "companies/" + companyId + "/climeco-certification-processes/" + certificationProcessId + "/toggle-estimations/" + id
        const response = await connectionsClient.client.patch(endpoint, {
            approved_date: toggle === "approve" ? new Date().toISOString().split("T")[0] : null,
            rejected_date: toggle === "reject" ? new Date().toISOString().split("T")[0] : null,
            reject_reason: reject_reason,
            overwrite_certification_date: overwrite_certification_date,
            pcf_amount: pcf_amount
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async toggleBadge({ id, marketPlaceBadgeId }: { id: number, marketPlaceBadgeId: number }): Promise<IEstimation> {
        const endpoint = "estimations/" + id + "/toggle-marketplace-badge/"
        const response = await connectionsClient.client.post(endpoint, {
            marketplace_id: marketPlaceBadgeId
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data;
    }

    async updateChecklist({ id, certificationProcessId, companyId, checklistDocument }: EstimateTypes.Checklist): Promise<IEstimation> {
        const formData = new FormData();
        formData.append("checklist_document", checklistDocument);

        const endpoint = companyId ? "companies/" + companyId + "/climeco-certification-processes/" + certificationProcessId + "/estimations/" + id : "/climeco-certification-processes/" + certificationProcessId + "/estimations/" + id
        const response = await connectionsClient.client.patch(endpoint, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data;
    }

    async delete({ id, companyId, certificationProcess }: EstimateTypes.Delete): Promise<{ res: string }> {
        const endpoint = companyId ? "companies/" + companyId + "/climeco-certification-processes/" + certificationProcess + "/estimations/" + id : "/climeco-certification-processes/" + certificationProcess + "/estimations/" + id
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }
}

export const estimation = new Estimation();