import { useState, useContext, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { form as formActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IForm } from "../../models";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";

export function FormsGrid() {
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [forms, setForms] = useState<Array<IForm> | null>(null);

	const { refetch } = useQuery(
		"forms",
		({ signal }) =>
			formActions.listForChild(user?.company.id, "", 1, "-created_at", 999),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForms(res.results);
			},
			onError: (error: any) => {
				enqueueSnackbar(t("forms.errorFetching"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company) {
			refetch();
		}
	}, [user?.company, refetch]);

	return (
		<>
			<Heading title={"Surveys"} showSearch={false} />
			<Box sx={{ display: "flex", gap: 4 }}>
				{forms ? (
					forms?.map((form) => (
						<Box
							sx={{
								padding: 3,
								textAlign: "center",
								border: "1px solid #B1D1F0",
								borderRadius: 4,
								cursor: "pointer",
							}}
							onClick={() => {
								navigate(
									`/companies/${form?.company.id}/form-display/${form.id}/`
								);
							}}
						>
							<Typography
								variant="h4"
								sx={{ fontWeight: "bold", marginBottom: 1 }}
							>
								{form.title}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Percentage of completion:{" "}
								{form.questions.filter((q) => (q.responses ?? []).length > 0)
									?.length +
									"/" +
									form.questions.length}
							</Typography>
						</Box>
					))
				) : (
					<CircularProgress />
				)}
			</Box>
		</>
	);
}
