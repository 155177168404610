import { useState, useEffect, useContext } from "react";
import { Box, Button } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { form as formActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IEntity, IForm } from "../../models";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";
import { IResponse } from "../../models/response-model";

export function Forms() {
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")?.includes("-") ? "desc" : "asc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [forms, setForms] = useState<Array<IForm>>([]);

	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"forms",
		({ signal }) =>
			formActions.list(
				user?.company.id,
				"",
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${sortModel[0].field}`
					: "-updated_at"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForms(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				enqueueSnackbar(t("forms.errorFetching"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, paginationModel, sortModel]);

	const columns = [
		{
			field: "id",
			headerName: t("forms.id"),
			flex: 1,
			valueGetter: ({ value }: { value: number }) => value.toString(),
		},
		{
			field: "title",
			headerName: t("forms.title"),
			flex: 2,
			minWidth: 200,
		},
		{
			field: "description",
			headerName: t("forms.description"),
			flex: 3,
			minWidth: 250,
		},
		{
			field: "created_at",
			headerName: t("forms.createdAt"),
			flex: 1,
			valueGetter: (params: GridRenderCellParams<Date>) =>
				new Date(params.value).toLocaleDateString(),
		},
		{
			field: "action",
			headerName: t("forms.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			width: 220,
			renderCell: (row: GridRenderCellParams<IResponse>) => (
				<Box sx={{ display: "flex", gap: 1 }}>
					<Button
						onClick={() => navigate(`/forms/${row.id}`)}
						variant="outlined"
					>
						{"Edit"}
					</Button>
					<Button
						onClick={() => navigate(`/form-stats/${row.id}`)}
						variant="outlined"
					>
						{"Stats"}
					</Button>
				</Box>
			),
		},
	];

	if (selectedRows) {
		console.log(selectedRows);
	}

	return (
		<Box>
			<Heading
				title={t("forms.title")}
				showSearch={true}
				setSearch={setSearch}
				actions={() => (
					<Box
						sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
					>
						<Button
							variant="contained"
							color="primary"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => navigate("/form")}
						>
							{t("forms.createFormButton")}
						</Button>
					</Box>
				)}
			/>
			<DataTable
				rows={forms}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "8px",
					marginTop: 2,
				}}
			/>
		</Box>
	);
}
