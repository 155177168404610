import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Typography,
	Button,
	IconButton,
	TextField,
	Divider,
	Switch,
	FormControlLabel,
	Chip,
} from "@mui/material";
import {
	MdAddCircle,
	MdDelete,
	MdArrowUpward,
	MdArrowDownward,
} from "react-icons/md";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { useMutation, useQuery } from "react-query";
import { UserContext } from "../../contexts/user";
import { IForm } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { form as formActions } from "../../controllers";

type QuestionType =
	| "multiple_choice"
	| "single_choice"
	| "text"
	| "datetime"
	| "rating"
	| "file";

interface Question {
	id: number;
	type: QuestionType;
	questionText: string;
	allowMultiple?: boolean;
	choices?: { id: number; text: string }[];
}

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 20,
		marginBottom: 2,
		fontWeight: 500,
		color: "#686868",
		display: "flex",
	},
	questionBox: {
		border: "1px solid #ccc",
		padding: 20,
		borderRadius: 8,
		marginBottom: 24,
		backgroundColor: "#f9f9f9",
		position: "relative",
	},
	chip: {
		position: "absolute",
		top: 10,
		right: 10,
		fontSize: 10,
		textTransform: "capitalize",
	},
	questionTextInput: {
		marginBottom: 16,
	},
	sectionTitle: {
		textTransform: "uppercase",
		fontSize: 12,
		marginTop: 32,
		marginBottom: 12,
		fontWeight: 600,
		color: "#686868",
	},
});

export const FormDetail: React.FC = () => {
	const [questions, setQuestions] = useState<Question[]>([]);
	const [originalQuestions, setOriginalQuestions] = useState<Question[]>([]);
	const classes = useStyles();
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const [form, setForm] = useState<IForm | null>();
	const navigate = useNavigate();

	const { id: paramsId } = useParams();
	const id = paramsId ? parseInt(paramsId) : 0;

	const handleAddQuestion = (type: QuestionType) => {
		const newQuestion: Question = {
			id: -1 * Date.now(),
			type,
			questionText: "",
			choices:
				type === "multiple_choice" || type === "single_choice"
					? [{ id: 1, text: "" }]
					: undefined,
		};
		setQuestions((prev) => [...prev, newQuestion]);
	};

	const handleQuestionChange = (id: number, value: string) => {
		setQuestions((prev) =>
			prev.map((q) => (q.id === id ? { ...q, questionText: value } : q))
		);
	};

	const handleAddChoice = (id: number) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === id
					? {
							...q,
							choices: q.choices
								? [...q.choices, { id: new Date().getTime(), text: "" }]
								: undefined,
					  }
					: q
			)
		);
	};

	const handleChoiceChange = (
		questionId: number,
		choiceIndex: number,
		value: string
	) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === questionId && q.choices
					? {
							...q,
							choices: q.choices.map((c, i) =>
								i === choiceIndex ? { ...c, text: value } : c
							),
					  }
					: q
			)
		);
	};

	const handleDeleteChoice = (questionId: number, choiceIndex: number) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === questionId && q.choices
					? {
							...q,
							choices: q.choices.filter((_, i) => i !== choiceIndex),
					  }
					: q
			)
		);
	};

	const handleDeleteQuestion = (id: number) => {
		setQuestions((prev) => prev.filter((q) => q.id !== id));
	};

	const handleMoveQuestion = (index: number, direction: "up" | "down") => {
		setQuestions((prev) => {
			const newQuestions = [...prev];
			const [movedQuestion] = newQuestions.splice(index, 1);
			const newIndex = direction === "up" ? index - 1 : index + 1;
			newQuestions.splice(newIndex, 0, movedQuestion);
			return newQuestions;
		});
	};

	const getChipColor = (type: QuestionType) => {
		switch (type) {
			case "multiple_choice":
				return "primary";
			case "single_choice":
				return "error";
			case "text":
				return "secondary";
			case "datetime":
				return "info";
			case "rating":
				return "warning";
			case "file":
				return "success";
			default:
				return "default";
		}
	};

	const { refetch } = useQuery(
		"forms",
		() =>
			formActions.getOne(
				id!,
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForm(res);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	const { mutate: addQuestion } = useMutation(
		async ({ formId, question }: { formId: number; question: Question }) => {
			if (!user?.company?.id) throw new Error(t("form.errorNoCompany"));
			const payload = {
				title: question.questionText,
				type: question.type.toUpperCase(),
				choices: question.choices ?? [],
				order: questions.findIndex((q) => q.id === question.id) + 1,
			};
			await formActions.addQuestion(user.company.id, formId, payload);
		},
		{
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(t("form.errorSavingQuestion"), { variant: "error" });
			},
		}
	);

	const { mutate: updateQuestion } = useMutation(
		async ({ formId, question }: { formId: number; question: Question }) => {
			if (!user?.company?.id) throw new Error(t("form.errorNoCompany"));
			const payload = {
				title: question.questionText,
				type: question.type.toUpperCase(),
				choices: question.choices ?? [],
				order: questions.findIndex((q) => q.id === question.id) + 1,
			};
			await formActions.updateQuestion(
				question.id,
				user.company.id,
				formId,
				payload
			);
		},
		{
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(t("form.errorSavingQuestion"), { variant: "error" });
			},
		}
	);

	const { mutate: deleteQuestion } = useMutation(
		async ({ formId, question }: { formId: number; question: Question }) => {
			if (!user?.company?.id) throw new Error(t("form.errorNoCompany"));
			await formActions.deleteQuestion(question.id, user.company.id, formId);
		},
		{
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(t("form.errorSavingQuestion"), { variant: "error" });
			},
		}
	);

	const { mutate } = useMutation(
		"forms",
		() =>
			id
				? formActions.update(
						id,
						form?.title || "",
						form?.description || "",
						form?.status ?? "DRAFT",
						user?.company.id
				  )
				: formActions.create(
						form?.title || "",
						form?.description || "",
						form?.status ?? "DRAFT",
						user?.company.id
				  ),

		{
			retry: false,
			onSuccess: async (res) => {
				setForm({ ...form!, id: res.id });
				for (const question of questions) {
					if (question.id > -1) {
						updateQuestion({ formId: res.id, question });
					} else {
						addQuestion({ formId: res.id, question });
					}
				}
				for (const question of originalQuestions.filter(
					(oq) => !questions.find((q) => q.id === oq.id)
				)) {
					deleteQuestion({ formId: res.id, question });
				}

				navigate("/forms");
				enqueueSnackbar(t("forms.success"), {
					variant: "success",
				});
			},
			onError: (error: any) => {
				if (error) {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	useQuery(
		["questions", id],
		async () => {
			if (!user?.company?.id || !id) return [];
			return await formActions.getQuestions(user.company.id, id);
		},
		{
			enabled: !!id,
			onSuccess: (data) => {
				setQuestions(
					data.map((q) => ({
						id: q.id,
						type: q.type.toLowerCase() as QuestionType,
						questionText: q.title,
						choices: q.choices || undefined,
					}))
				);
				setOriginalQuestions(
					data.map((q) => ({
						id: q.id,
						type: q.type.toLowerCase() as QuestionType,
						questionText: q.title,
						choices: q.choices || undefined,
					}))
				);
			},
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(t("form.errorFetchingQuestions"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (id) {
			refetch();
		}
	}, [id, refetch]);

	return (
		<Box p={0}>
			<Heading title={t("form.createForm")} showSearch={false} noMarginBottom />
			<span className={classes.inputLabel}>{t("form.title")}</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="title"
				placeholder={t("form.title")}
				value={form?.title}
				onChange={(e) => {
					setForm(
						(prevState) =>
							({
								...prevState,
								title: e.target.value,
							} as IForm)
					);
				}}
			/>
			<span className={classes.inputLabel}>{t("form.description")}</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="description"
				placeholder={t("form.description")}
				value={form?.description}
				onChange={(e) => {
					setForm(
						(prevState) =>
							({
								...prevState,
								description: e.target.value,
							} as IForm)
					);
				}}
			/>
			<FormControlLabel
				control={
					<Switch
						checked={form?.status === "ACTIVE" ? true : false}
						onChange={(e) => {
							setForm(
								(prevState) =>
									({
										...prevState,
										status: e.target.checked ? "ACTIVE" : "DRAFT",
									} as IForm)
							);
						}}
						color="primary"
					/>
				}
				label={form?.status === "ACTIVE" ? t("form.active") : t("form.draft")}
				sx={{
					".MuiFormControlLabel-label": {
						fontSize: "14px",
					},
					display: "flex",
					alignItems: "center",
					marginTop: 2,
				}}
			/>
			<Divider sx={{ marginY: 2 }} />

			{/* Sección de preguntas */}
			<Typography className={classes.sectionTitle}>
				{t("form.questionsSectionTitle")}
			</Typography>
			<Box mb={2}>
				<Button
					variant="contained"
					color="primary"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("multiple_choice")}
				>
					{t("form.addMultipleChoice")}
				</Button>
				<Button
					variant="contained"
					color="error"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("single_choice")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addSingleChoice")}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("text")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addTextField")}
				</Button>
				<Button
					variant="contained"
					color="info"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("datetime")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addDate")}
				</Button>
				<Button
					variant="contained"
					color="warning"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("rating")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addRating")}
				</Button>
				<Button
					variant="contained"
					color="success"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("file")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addFile")}
				</Button>
			</Box>

			{/* Renderizar preguntas */}
			{questions.map((question, index) => (
				<Box key={question.id} className={classes.questionBox}>
					<Chip
						label={t(`form.type.${question.type}`)}
						className={classes.chip}
						color={getChipColor(question.type)}
					/>

					<span className={classes.inputLabel}>
						{t("form.questionNumber", { index: index + 1 })}
					</span>
					<TextField
						fullWidth
						placeholder={t("form.enterQuestionText")}
						variant="outlined"
						value={question.questionText}
						onChange={(e) => handleQuestionChange(question.id, e.target.value)}
						className={classes.questionTextInput}
					/>

					{["date", "rating", "file"].includes(question.type) && (
						<Typography variant="caption" color="textSecondary">
							{t(`form.requiredInput.${question.type}`)}
						</Typography>
					)}

					{(question.type === "multiple_choice" ||
						question.type === "single_choice") && (
						<Box>
							<Typography className={classes.inputLabel}>
								{t("form.choices")}
							</Typography>
							{question.choices?.map((choice, i) => (
								<Box key={i} display="flex" alignItems="center" mb={1}>
									<TextField
										fullWidth
										placeholder={t("form.enterChoice", { index: i + 1 })}
										value={choice.text}
										onChange={(e) =>
											handleChoiceChange(question.id, i, e.target.value)
										}
										variant="outlined"
									/>
									<IconButton
										aria-label="delete choice"
										color="error"
										onClick={() => handleDeleteChoice(question.id, i)}
									>
										<MdDelete />
									</IconButton>
								</Box>
							))}
							<Button
								onClick={() => handleAddChoice(question.id)}
								color="primary"
							>
								{t("form.addChoice")}
							</Button>
						</Box>
					)}

					<Box display="flex" justifyContent="space-between" mt={2}>
						<Box>
							<IconButton
								disabled={index === 0}
								onClick={() => handleMoveQuestion(index, "up")}
							>
								<MdArrowUpward />
							</IconButton>
							<IconButton
								disabled={index === questions.length - 1}
								onClick={() => handleMoveQuestion(index, "down")}
							>
								<MdArrowDownward />
							</IconButton>
						</Box>
						<IconButton
							aria-label="delete"
							color="error"
							onClick={() => handleDeleteQuestion(question.id)}
						>
							<MdDelete />
						</IconButton>
					</Box>
				</Box>
			))}

			<Divider sx={{ marginY: 2 }} />

			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					onClick={() => {
						mutate();
					}}
					color="primary"
					variant="contained"
				>
					{id ? t("form.save") : t("form.createForm")}
				</Button>
			</Box>
		</Box>
	);
};
