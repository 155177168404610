import { useState, useEffect, useContext } from "react";
import { Typography, Box, CircularProgress, Grid } from "@mui/material";
import { PropTypes } from "./Dashboard.types";
import { Heading } from "../Heading";
import { Updates } from "../Updates";
import { useQuery } from "react-query";
import {
	notification as notificationActions,
	certification as certificationActions,
	form as formActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { ICertification, INotification } from "../../models";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import InitialStep from "../InitialStep/InitialStep";
import { QuickActions } from "../QuickActions";
import { TbGraph } from "react-icons/tb";
import { TiDocumentAdd } from "react-icons/ti";
import { MyApplications } from "../MyApplications";
import { useTranslation } from "react-i18next";
import { DisplayContext } from "../../contexts/display";
import InitialStepSupplier from "../InitialStepSupplier/InitialStepSupplier";
import { FormsGrid } from "../FormsGrid";

export function Dashboard(props: PropTypes) {
	const { user } = useContext(UserContext);
	const { displayMode } = useContext(DisplayContext);
	const [forms, setForms] = useState<{
		forms: number;
		started_forms: number;
		completed_forms: number;
	} | null>(null);
	const [notifications, setNotifications] = useState<INotification[] | null>(
		null
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [hasAmazonCertificationTodo, setHasAmazonCertificationTodo] = useState<
		boolean | null
	>(null);
	const [firstTime, setFirstTime] = useState<boolean | null>(null);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);

	const { t } = useTranslation();

	const { isLoading, refetch: refetchNotifications } = useQuery(
		"notifications",
		() => notificationActions.list(5, user?.company.id, undefined, true),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setNotifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		refetch: refetchClimecoCertifications,
		isLoading: isLoadingClimecoCertifications,
	} = useQuery(
		"certifications-climeco-dashboard",
		() =>
			certificationActions.list(
				true,
				user?.company.id,
				undefined,
				undefined,
				undefined,
				undefined,
				999,
				false
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setFirstTime(res.results.length > 0 ? false : true);
				const lcas = Array<number>();
				setCertifications(
					res.results.filter((certification) => {
						const foundLCA = lcas.find((lca) =>
							certification.lca_versions?.find(
								(auxLCAVersion) => auxLCAVersion.lca_id === lca
							)
						);

						if (
							foundLCA === undefined &&
							certification.lca_versions &&
							certification.lca_versions.length > 0
						) {
							lcas.push(certification.lca_versions![0].lca_id ?? -1);
						}

						return !foundLCA || certification.status === "COMPLETED";
					})
				);
				const foundCompletedCertification = res.results.find(
					(c) => c.status === "COMPLETED" && !c.is_historical
				)
					? true
					: false;

				setHasAmazonCertificationTodo(foundCompletedCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchForms } = useQuery(
		"forms-dashboard",
		() => formActions.getDashboardFormStats(user?.company.id ?? -1),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForms(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id) {
			refetchNotifications();
			refetchClimecoCertifications();
			refetchForms();
		}
	}, [user, refetchNotifications, refetchClimecoCertifications, refetchForms]);

	return (
		<Box>
			{displayMode === "SUPPLIER" ? (
				user?.company?.is_parent_company ? (
					<>
						<Box sx={{ marginBottom: 4 }}>
							{forms && forms.forms > 0 && (
								<>
									<Heading
										title={t("forms.summaryDashboardTitle")}
										showSearch={false}
										noMarginBottom
									/>
									<Grid container spacing={4}>
										<Grid item xs={12} sm={6} md={4}>
											<Box
												sx={{
													padding: 3,
													textAlign: "center",
													border: "1px solid #B1D1F0",
													borderRadius: 4,
												}}
											>
												<Typography
													variant="h4"
													sx={{ fontWeight: "bold", marginBottom: 1 }}
												>
													{forms.forms}
												</Typography>
												<Typography variant="body2" color="textSecondary">
													{"Total forms"}
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<Box
												sx={{
													padding: 3,
													textAlign: "center",
													border: "1px solid #B1D1F0",
													borderRadius: 4,
												}}
											>
												<Typography
													variant="h4"
													sx={{ fontWeight: "bold", marginBottom: 1 }}
												>
													{forms.started_forms}
												</Typography>
												<Typography variant="body2" color="textSecondary">
													{"Started Forms"}
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<Box
												sx={{
													padding: 3,
													textAlign: "center",
													border: "1px solid #B1D1F0",
													borderRadius: 4,
												}}
											>
												<Typography
													variant="h4"
													sx={{ fontWeight: "bold", marginBottom: 1 }}
												>
													{forms.completed_forms}
												</Typography>
												<Typography variant="body2" color="textSecondary">
													{"Completed Forms"}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</>
							)}
						</Box>
						<InitialStepSupplier />
					</>
				) : (
					<Box>
						<FormsGrid />
					</Box>
				)
			) : firstTime === null ? (
				<Box
					sx={{
						display: "flex",
						padding: 4,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : firstTime && user && !user.is_superuser ? (
				<Box sx={{ width: { xs: 400, md: 800 } }}>
					<InitialStep />
				</Box>
			) : (
				<>
					{!user?.is_superuser && (
						<>
							<Heading
								title={t("dashboard.quickActions")}
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>
							<Box
								sx={{
									maxWidth: 800,
									width: "100%",
									display: "flex",
									flexDirection: { xs: "column", sm: "row" },
									gap: 2,
									marginBottom: 4,
								}}
							>
								<QuickActions
									title={t("dashboard.getCertified")}
									description={t("dashboard.applyToGetCertified")}
									buttonTitle={t("dashboard.getCertified")}
									icon={<TiDocumentAdd size={26} />}
									callback={() =>
										navigate("/lca", {
											state: {
												previousPage: window.location.href.includes(":3000")
													? window.location.href.split(":3000")[1]
													: window.location.href.split(".com")[1],
											},
										})
									}
									buttonType="contained"
								/>
								<QuickActions
									title={t("dashboard.stayCertified")}
									description={t("dashboard.updateProductSales")}
									buttonTitle={t("dashboard.updateSales")}
									icon={<TbGraph size={24} />}
									callback={() => navigate("/sales")}
									buttonType="outlined"
								/>
							</Box>
						</>
					)}

					{certifications && certifications.length > 0 && (
						<>
							<Heading
								title={t("dashboard.myLatestApplications")}
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>

							<MyApplications
								certifications={certifications}
								loading={isLoadingClimecoCertifications}
							/>
						</>
					)}
					<Heading
						title={t("dashboard.updates")}
						subtitle=""
						showSearch={false}
						noMarginBottom
					/>
					<Box
						sx={{
							backgroundColor: "#fff",
							marginBottom: 8,
							maxWidth: "800px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{isLoading || isLoadingClimecoCertifications ? (
							<CircularProgress size={24} />
						) : notifications && notifications.length > 0 ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
									}}
								>
									{notifications
										.slice(0, 3)
										.map((notification, index, array) => {
											return (
												<Box key={notification.id}>
													{(index === 0 ||
														(index > 0 &&
															new Date(
																array[index - 1].created_at
															).getDate() !==
																new Date(array[index].created_at).getDate() &&
															new Date(
																array[index - 1].created_at
															).getMonth() !==
																new Date(array[index].created_at).getMonth() &&
															new Date(
																array[index - 1].created_at
															).getFullYear() !==
																new Date(
																	array[index].created_at
																).getFullYear())) && (
														<Typography
															sx={{
																fontSize: 12,
																color: "#777",
																textTransform: "uppercase",
																marginBottom: 1,
																fontWeight: "bold",
															}}
														>
															{new Intl.DateTimeFormat("en", {
																day: "2-digit",
																month: "2-digit",
																year: "numeric",
															}).format(new Date(notification.created_at))}
														</Typography>
													)}
													<Updates
														key={notification.id}
														notification={notification}
														callback={() => {
															setNotifications((prev) => {
																const auxNotifications = [...prev!];
																const index = auxNotifications.findIndex(
																	(n) => n.id === notification.id
																);
																if (index > -1) {
																	auxNotifications.splice(index, 1);
																}
																return auxNotifications;
															});
															refetchNotifications();
														}}
														productsLength={0}
													/>
												</Box>
											);
										})}
								</Box>
								{notifications.length > 2 && (
									<Typography
										variant="body2"
										onClick={() => {
											navigate("/notifications/");
										}}
										sx={{
											textAlign: "right",
											color: "#34918E",
											fontWeight: "bold",
											marginBottom: 4,
											marginTop: 1,
											fontSize: 12,
											cursor: "pointer",
											"&:hover": { textDecoration: "underline" },
										}}
									>
										{t("dashboard.seeMore")}
									</Typography>
								)}
							</Box>
						) : hasAmazonCertificationTodo ? (
							<Updates
								key={-1}
								notification={{
									id: -1,
									title: t("dashboard.amazonCertification"),
									message: "",
									notification_type: "START_EXTERNAL_CERTIFICATION",
									status: "",
									created_at: new Date().toISOString(),
									users: [],
									product_id: null,
									certification_process_id: null,
									lca_version: null,
									sale_id: null,
									button_title: t("dashboard.apply"),
								}}
								callback={() => {}}
								hideCheckbox={true}
								productsLength={0}
							/>
						) : (
							<Typography variant="body1">
								{t("notifications.noNew")}
							</Typography>
						)}
					</Box>
				</>
			)}
		</Box>
	);
}
