import React, { useContext } from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import {
	Certifications,
	CompanyProfile,
	Products,
	SalesReport,
	UserProfile,
	ClimecoCertifications,
	Companies,
	CompanyDetail,
	Documents,
	Dashboard,
	NotFoundPage,
	LCAs,
	BulkUpload,
	BulkUploadDetail,
	FormStats,
} from "../components";
import { Users } from "../components/Users";
import { UserDetail } from "../components/UserDetail";
import { Notifications } from "../components/Notifications";
import { SalesReportDetail } from "../components/SalesReportDetail/SalesReportDetail";
import { UserContext } from "../contexts/user";
import { IUser } from "../models/user-model";
import GetCertified from "../components/GetCertified/GetCertified";
import GetAmazonCertified from "../components/GetAmazonCertified/GetAmazonCertified";
import { DisplayContext } from "../contexts/display";
import { FormDetail } from "../components/FormDetails";
import { FormDisplay } from "../components/FormDisplay";
import { Forms } from "../components/Forms";

const ProtectedRoute = ({
	user,
	children,
}: {
	user: IUser | null;
	children: JSX.Element;
}): JSX.Element => {
	if (!user || !user.company) {
		return <Navigate to="/" replace />;
	}

	return children;
};

const AdminProtectedRoute = ({
	user,
	children,
}: {
	user: IUser | null;
	children: JSX.Element;
}): JSX.Element => {
	if (!user || !user.is_superuser) {
		return <Navigate to="/" replace />;
	}

	return children;
};

const AdminOrParentProtectedRoute = ({
	user,
	children,
}: {
	user: IUser | null;
	children: JSX.Element;
}): JSX.Element => {
	if (!user || (!user?.is_superuser && !user?.company?.is_parent_company)) {
		return <Navigate to="/" replace />;
	}

	return children;
};

export default function Routes() {
	const { user } = useContext(UserContext);
	const { displayMode } = useContext(DisplayContext);

	return (
		<Switch>
			<Route path="/" element={<Dashboard />} />
			<Route
				path="/users"
				element={
					<ProtectedRoute user={user}>
						<Users />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/companies"
				element={
					<AdminOrParentProtectedRoute user={user}>
						<Companies />
					</AdminOrParentProtectedRoute>
				}
			/>
			<Route
				path="/company/:id"
				element={
					<AdminOrParentProtectedRoute user={user}>
						<CompanyDetail />
					</AdminOrParentProtectedRoute>
				}
			/>
			<Route
				path="/company"
				element={
					<AdminOrParentProtectedRoute user={user}>
						<CompanyDetail />
					</AdminOrParentProtectedRoute>
				}
			/>

			<Route
				path="/user"
				element={
					<ProtectedRoute user={user}>
						<UserDetail />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/user/:id"
				element={
					<ProtectedRoute user={user}>
						<UserDetail />
					</ProtectedRoute>
				}
			/>

			<Route
				path="/profile"
				element={
					<ProtectedRoute user={user}>
						<UserProfile />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/company-profile"
				element={
					<ProtectedRoute user={user}>
						<CompanyProfile />
					</ProtectedRoute>
				}
			/>

			{displayMode === "SUPPLIER" ? (
				<>
					<Route
						path="/forms"
						element={
							<ProtectedRoute user={user}>
								<Forms />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/form"
						element={
							<ProtectedRoute user={user}>
								<FormDetail />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/forms/:id"
						element={
							<ProtectedRoute user={user}>
								<FormDetail />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/form-stats/:id"
						element={
							<ProtectedRoute user={user}>
								<FormStats />
							</ProtectedRoute>
						}
					/>
					<Route
						path="companies/:companyId/form-display/:id"
						element={
							<ProtectedRoute user={user}>
								<FormDisplay />
							</ProtectedRoute>
						}
					/>
				</>
			) : (
				<>
					<Route
						path="/products"
						element={
							<ProtectedRoute user={user}>
								<Products />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/lcas"
						element={
							<ProtectedRoute user={user}>
								<LCAs />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/lca"
						element={
							<ProtectedRoute user={user}>
								<GetCertified defaultOpen />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/lcas/:id"
						element={
							<ProtectedRoute user={user}>
								<GetCertified defaultOpen />
								{/*<LCA />*/}
							</ProtectedRoute>
						}
					/>
					<Route
						path="/certifications"
						element={
							<ProtectedRoute user={user}>
								<Certifications />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/certifications/:id"
						element={
							<ProtectedRoute user={user}>
								<GetAmazonCertified defaultOpen />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/climeco-certifications"
						element={
							<ProtectedRoute user={user}>
								<ClimecoCertifications />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/climeco-certificationss/:id"
						element={
							<ProtectedRoute user={user}>
								<GetCertified defaultOpen />
								{/*<ClimecoCertificationProcesss />*/}
							</ProtectedRoute>
						}
					/>
					<Route
						path="/historical-data/"
						element={
							<ProtectedRoute user={user}>
								<ClimecoCertifications />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/sales"
						element={
							<ProtectedRoute user={user}>
								<SalesReport />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/sale"
						element={
							<ProtectedRoute user={user}>
								<SalesReportDetail />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/sales/:id"
						element={
							<ProtectedRoute user={user}>
								<SalesReportDetail />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/notifications"
						element={
							<ProtectedRoute user={user}>
								<Notifications />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/documents"
						element={
							<AdminProtectedRoute user={user}>
								<Documents />
							</AdminProtectedRoute>
						}
					/>
					<Route
						path="/bulk-uploads"
						element={
							<AdminProtectedRoute user={user}>
								<BulkUpload />
							</AdminProtectedRoute>
						}
					/>
					<Route
						path="/bulk-uploads/create"
						element={
							<AdminProtectedRoute user={user}>
								<BulkUploadDetail />
							</AdminProtectedRoute>
						}
					/>
					<Route
						path="/bulk-uploads/:id"
						element={
							<AdminProtectedRoute user={user}>
								<BulkUploadDetail />
							</AdminProtectedRoute>
						}
					/>
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/404" element={<NotFoundPage />} />
				</>
			)}
		</Switch>
	);
}
