import React, { useState } from "react";
import { DisplayContextType, PropTypes } from "./DisplayContext.types";

export const DisplayContext = React.createContext<DisplayContextType>({
	displayMode: "SUPPLIER",
	setDisplayMode: (displayMode: "SUPPLIER" | "PRODUCTS") => {},
});

export const DisplayProvider: React.FC<PropTypes> = ({ children }) => {
	const [displayMode, setDisplayMode] = useState<"SUPPLIER" | "PRODUCTS">(
		window.location.origin.includes("supplierportal.climeco.com")
			? "SUPPLIER"
			: "PRODUCTS"
	);

	return (
		<DisplayContext.Provider value={{ displayMode, setDisplayMode }}>
			{children}
		</DisplayContext.Provider>
	);
};
