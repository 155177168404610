import { useState, useEffect } from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { bulkUpload as bulkUploadActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IBulkUpload, IEntity } from "../../models";
import { Filters } from "../../routes/Routes.types";
import { useTranslation } from "react-i18next";
import { BulkUploadActionBar } from "../BulkUploadActionBar";

export function BulkUpload() {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [bulkUploads, setBulkUploads] = useState<Array<IBulkUpload>>([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"bulk-uploads",
		({ signal }) =>
			bulkUploadActions.list(
				search,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				10,
				signal
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBulkUploads(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	useEffect(() => {
		setPaginationModel({
			page: 0,
			pageSize: 10,
		});
	}, [search]);

	const columns = [
		{
			field: "id",
			headerName: t("bulkUploads.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{
			field: "name",
			headerName: t("bulkUploads.name"),
			flex: 1,
			minWidth: 150,
		},
		{
			field: "step",
			headerName: t("bulkUploads.step"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				let currentStep = params.row?.is_add_products ? "OFFSETS" : "COMPANIES";

				if (params.row?.is_add_products) {
					if (params.row?.amazon_certifications_step) {
						currentStep = "COMPLETED";
					} else if (params.row?.estimations_and_sales_emissions_step) {
						currentStep = "COMPLETED / AMAZON";
					} else if (params.row?.product_lca_emissions_step) {
						currentStep = "ESTIMATIONS";
					} else if (params.row?.lcas_step) {
						currentStep = "PROD. LCAS EMISSIONS";
					} else if (params.row?.products_step) {
						currentStep = "LCAS";
					} else if (params.row?.offsets_step) {
						currentStep = "PRODUCTS";
					}
				} else {
					if (params.row?.amazon_certifications_step) {
						currentStep = "COMPLETED";
					} else if (params.row?.estimations_and_sales_emissions_step) {
						currentStep = "COMPLETED / AMAZON";
					} else if (params.row?.climeco_certifications_step) {
						currentStep = "ESTIMATIONS";
					} else if (params.row?.product_lca_emissions_step) {
						currentStep = "CERTIFICATIONS";
					} else if (params.row?.lcas_step) {
						currentStep = "PROD. LCAS EMISSIONS";
					} else if (params.row?.products_step) {
						currentStep = "LCAS";
					} else if (params.row?.offsets_step) {
						currentStep = "PRODUCTS";
					} else if (params.row?.companies_step) {
						currentStep = "OFFSETS";
					}
				}

				return (
					<Chip
						label={currentStep}
						size={"small"}
						sx={{
							background:
								currentStep === "COMPLETED"
									? "#6ab04c"
									: currentStep === "COMPLETED / AMAZON"
									? "linear-gradient(90deg, #6BB04C, #F9CA25)"
									: "#f9ca24",
							color: "#fff",
							fontWeight: "bold",
							fontSize: 10,
							textTransform: "uppercase",
						}}
					/>
				);
			},
		},
		{
			field: "is_add_products",
			headerName: t("bulkUploadDetail.isAddProductsTitle"),
			flex: 1,
			minWidth: 150,
		},
		{
			field: "user",
			headerName: t("bulkUploads.admin"),
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<any>) => {
				if (params.value == null) {
					return "";
				}

				return params.value && params.value.email;
			},
		},
		{
			field: "created_at",
			headerName: t("bulkUploads.createdAt"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{params.row.created_at &&
							Intl.DateTimeFormat("en", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
								timeZone: "UTC",
							}).format(new Date(params.row.created_at))}
					</Typography>
				);
			},
		},
		{
			field: "updated_at",
			headerName: t("bulkUploads.updatedAt"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{params.row.created_at &&
							Intl.DateTimeFormat("en", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
								timeZone: "UTC",
							}).format(new Date(params.row.created_at))}
					</Typography>
				);
			},
		},
		{
			field: "action",
			headerName: t("bulkUploads.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			width: 200,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					navigate("/bulk-uploads/" + row.id);
				};

				return <Button onClick={onClickEdit}>{t("bulkUploads.view")}</Button>;
			},
		},
	];

	return (
		<Box>
			<Heading
				title={t("sidebar.bulkUploads")}
				showSearch
				actions={() => (
					<BulkUploadActionBar
						selectedRows={selectedRows}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
					/>
				)}
				setSearch={setSearch}
			/>
			<DataTable
				rows={bulkUploads}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
