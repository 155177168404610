import { IBulkUpload } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"


class BulkUpload {
    async list(search?: string, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<IBulkUpload> }> {
        const endpoint = "/bulk-uploads"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", page_size: pageSize, /*is_test_company: localStorage.getItem("showTestData") ? undefined : false*/ },
            signal
        });
        return response.data;
    }

    async getOne(id: number): Promise<IBulkUpload> {
        const endpoint = "/bulk-uploads/" + id
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async create(name: string, file: File | string | undefined, is_add_products: boolean): Promise<IBulkUpload> {
        const endpoint = "bulk-uploads/"

        const formData = new FormData();
        formData.append("name", name!);
        formData.append("file", file!);
        formData.append("is_add_products", is_add_products ? "true" : "false")

        const response = await connectionsClient.client.post(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async update({ id, name, file, is_add_products }: { id: number, name?: string, file?: File | string | undefined, is_add_products?: boolean }): Promise<IBulkUpload> {
        const endpoint = "bulk-uploads/" + id

        const formData = new FormData();
        if (name) {
            formData.append("name", name!);
        }
        if (file) {
            formData.append("file", file!);
        }
        if (is_add_products !== undefined) {
            formData.append("is_add_products", is_add_products ? "true" : "false");
        }

        const response = await connectionsClient.client.patch(endpoint, formData, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    }

    async action(id: number, action: "COMPANIES_STEP" | "OFFSETS_STEP" | "PRODUCTS_STEP" |
        "LCAS_STEP" | "PRODUCT_LCA_EMISSIONS_STEP" |
        "CLIMECO_CERTIFICATIONS_STEP" | "ESTIMATIONS_AND_SALES_STEP" |
        "AMAZON_CERTIFICATIONS_STEP"): Promise<IBulkUpload> {
        const endpoint = "bulk-uploads/" + id + "/action/"
        const response = await connectionsClient.client.post(endpoint, { action });
        return response.data;
    }

    async delete(bulkUploadIds: number[]): Promise<void> {
        const deletePromises = bulkUploadIds.map((bulkUploadId) =>
            connectionsClient.client.delete(`/bulk-uploads/${bulkUploadId}`)
        );
        await Promise.all(deletePromises);
    }
}

export const bulkUpload = new BulkUpload();