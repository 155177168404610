import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	TextField,
	Typography,
	Divider,
	Alert,
	Stepper,
	Step,
	StepLabel,
	Chip,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { IBulkUpload } from "../../models";
import { bulkUpload as bulkUploadActions } from "../../controllers";
import { BasicModal } from "../BasicModal";
import { useTranslation } from "react-i18next";
import { UploadFile } from "../UploadFile";
import { useWindowSize } from "@uidotdev/usehooks";

const useStyles = makeStyles({
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function BulkUploadDetail() {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = paramsId === "create" ? 0 : paramsId ? parseInt(paramsId) : -1;
	const [bulkUpload, setBulkUpload] = useState<IBulkUpload | null>({
		id: 0,
		name: "",
		file: null,
		is_add_products: false,
	});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showTriggerModal, setShowTriggerModal] = useState(false);
	const { t } = useTranslation();
	const size = useWindowSize();
	const [selectedStep, setSelectedStep] = useState(-1);
	const [currentStep, setCurrentStep] = useState(0);

	const { refetch } = useQuery(
		"bulk-upload-detail",
		() => bulkUploadActions.getOne(id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBulkUpload(res);
				let currentStep = res?.is_add_products ? 1 : 0;

				if (res?.is_add_products) {
					if (res?.amazon_certifications_step) {
						currentStep = 8;
					} else if (res?.estimations_and_sales_emissions_step) {
						currentStep = 7;
					} else if (res?.product_lca_emissions_step) {
						currentStep = 6;
					} else if (res?.lcas_step) {
						currentStep = 4;
					} else if (res?.products_step) {
						currentStep = 3;
					} else if (res?.offsets_step) {
						currentStep = 2;
					}
				} else {
					if (res?.amazon_certifications_step) {
						currentStep = 8;
					} else if (res?.estimations_and_sales_emissions_step) {
						currentStep = 7;
					} else if (res?.climeco_certifications_step) {
						currentStep = 6;
					} else if (res?.product_lca_emissions_step) {
						currentStep = 5;
					} else if (res?.lcas_step) {
						currentStep = 4;
					} else if (res?.products_step) {
						currentStep = 3;
					} else if (res?.offsets_step) {
						currentStep = 2;
					} else if (res?.companies_step) {
						currentStep = 1;
					}
				}

				setCurrentStep(currentStep);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"bulk-uploads-create",
		() =>
			id > 0
				? bulkUploadActions.update({
						id: id,
						name: bulkUpload?.name!,
						file: bulkUpload?.file ?? undefined,
				  })
				: bulkUploadActions.create(
						bulkUpload?.name!,
						bulkUpload?.file!,
						bulkUpload?.is_add_products!
				  ),
		{
			retry: false,
			onSuccess: (res) => {
				if (id > 0) {
					enqueueSnackbar("Changes saved", {
						variant: "success",
					});
				} else {
					enqueueSnackbar("Bulk upload created", {
						variant: "success",
					});
					navigate("/bulk-uploads/" + res.id);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);
	useEffect(() => {
		if (id > 0) {
			refetch();
		}
	}, [id, refetch]);

	const { isLoading: isLoadingMutateAction, mutate: mutateAction } =
		useMutation(
			"bulk-upload-action",
			({
				action,
			}: {
				action:
					| "COMPANIES_STEP"
					| "OFFSETS_STEP"
					| "PRODUCTS_STEP"
					| "LCAS_STEP"
					| "PRODUCT_LCA_EMISSIONS_STEP"
					| "CLIMECO_CERTIFICATIONS_STEP"
					| "ESTIMATIONS_AND_SALES_STEP"
					| "AMAZON_CERTIFICATIONS_STEP";
			}) => bulkUploadActions.action(id, action),
			{
				retry: false,
				onSuccess: (res) => {
					refetch();
					enqueueSnackbar("Step successfully completed.", {
						variant: "success",
					});
				},
				onError: (error: any) => {
					console.log(error);
					refetch();
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const validateBulkUpload = () => {
		return bulkUpload?.name && bulkUpload?.file;
	};

	const steps = [
		{
			id: "COMPANIES_STEP",
			title: "Create the companies",
			action: () => mutateAction({ action: "COMPANIES_STEP" }),
			isAddProducts: false,
		},
		{
			id: "OFFSETS_STEP",
			title: "Create the offsets",
			action: () => mutateAction({ action: "OFFSETS_STEP" }),
			isAddProducts: true,
		},
		{
			id: "PRODUCTS_STEP",
			title: "Create the products",
			action: () => mutateAction({ action: "PRODUCTS_STEP" }),
			isAddProducts: true,
		},
		{
			id: "LCAS_STEP",
			title: "Create the LCAs",
			action: () => mutateAction({ action: "LCAS_STEP" }),
			isAddProducts: true,
		},
		{
			id: "PRODUCT_LCA_EMISSIONS_STEP",
			title: "Create the Product LCAs Emissions",
			action: () => mutateAction({ action: "PRODUCT_LCA_EMISSIONS_STEP" }),
			isAddProducts: true,
		},
		{
			id: "CLIMECO_CERTIFICATIONS_STEP",
			title: "Create the ClimeCo Certifications",
			action: () => mutateAction({ action: "CLIMECO_CERTIFICATIONS_STEP" }),
			isAddProducts: false,
		},
		{
			id: "ESTIMATIONS_AND_SALES_STEP",
			title: "Create the Estimations and Sales",
			action: () => mutateAction({ action: "ESTIMATIONS_AND_SALES_STEP" }),
			isAddProducts: true,
		},
		{
			id: "AMAZON_CERTIFICATIONS_STEP",
			title: "Create the Amazon Certifications",
			action: () => mutateAction({ action: "AMAZON_CERTIFICATIONS_STEP" }),
			isAddProducts: true,
		},
		{
			id: "COMPLETED",
			title: "Bulk Upload completed!",
			isAddProducts: true,
		},
	];

	return (
		<Box>
			<Heading
				title={
					id
						? t("bulkUploadDetail.editTitle")
						: t("bulkUploadDetail.createTitle")
				}
				showSearch={false}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<Alert severity="info">
					{t("bulkUploadDetail.guidelines1")}
					<ol>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines2")}
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines3")}
							<ul>
								<li style={{ listStyleType: "inherit" }}>
									{t("bulkUploadDetail.guidelines4")}
								</li>
								<li style={{ listStyleType: "inherit" }}>
									{t("bulkUploadDetail.guidelines5")}
								</li>
								<li style={{ listStyleType: "inherit" }}>
									{t("bulkUploadDetail.guidelines6")}
								</li>
							</ul>
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines7")}
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines8")}
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines9")}
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines10")}
						</li>
						<li style={{ listStyleType: "inherit" }}>
							{t("bulkUploadDetail.guidelines11")}
						</li>
					</ol>
					<br />
					<b>{t("bulkUploadDetail.guidelines12")}</b>{" "}
					{t("bulkUploadDetail.guidelines13")}
				</Alert>
			</Box>
			<Typography
				variant={"h6"}
				sx={{
					textTransform: "uppercase",
					fontSize: 16,
					fontWeight: "bold",
					marginTop: 4,
					marginBottom: 1,
				}}
			>
				{t("bulkUploadDetail.bulkUploadDetails")}
			</Typography>
			<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
			<FormControl sx={{ width: "100%" }}>
				<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
					<span className={classes.inputLabel}>
						{t("bulkUploadDetail.name")}
					</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="name"
						value={bulkUpload?.name}
						placeholder={t("bulkUploadDetail.namePlaceholder")}
						onChange={(e) => {
							setBulkUpload(
								(prevState) =>
									({
										...prevState,
										name: e.target.value,
									} as IBulkUpload)
							);
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						marginTop: 3,
						marginBottom: 3,
					}}
				>
					<span className={classes.inputLabel}>
						{t("bulkUploadDetail.file")}
					</span>
					<UploadFile
						padding={0}
						flex={1}
						accept=".zip"
						value={bulkUpload?.file}
						onFileChange={(file) => {
							if (file) {
								setBulkUpload(
									(prevState) =>
										({
											...prevState,
											file: file,
										} as IBulkUpload)
								);
							}
						}}
					/>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
					<span className={classes.inputLabel}>
						{t("bulkUploadDetail.isAddProductsTitle")}
					</span>
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								size="small"
								checked={bulkUpload?.is_add_products || false}
								onChange={(e) => {
									if (bulkUpload?.id === 0) {
										setCurrentStep(e.target.checked ? 1 : 0);
									}
									setBulkUpload(
										(prevState) =>
											({
												...prevState,
												is_add_products: e.target.checked,
											} as IBulkUpload)
									);
								}}
							/>
						}
						label={t("bulkUploadDetail.isAddProducts")}
						labelPlacement="end"
						sx={{ marginRight: 0.5, span: { fontSize: 14 } }}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						marginTop: 2,
						justifyContent: "flex-end",
					}}
				>
					<Button
						variant="contained"
						onClick={() => {
							if (!validateBulkUpload()) {
								enqueueSnackbar("Data is not valid", {
									variant: "error",
								});
							} else {
								mutate();
							}
						}}
						sx={{
							marginTop: 2,
							minHeight: 34,
							maxWidth: 300,
							alignSelf: "end",
						}}
						disabled={isLoading || !validateBulkUpload()}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : id > 0 ? (
							t("bulkUploadDetail.save")
						) : (
							t("bulkUploadDetail.create")
						)}
					</Button>
				</Box>
			</FormControl>

			<Divider sx={{ marginTop: 6, marginBottom: 6 }} />
			<Typography
				variant={"h6"}
				sx={{
					textTransform: "uppercase",
					fontSize: 16,
					fontWeight: "bold",
					marginBottom: 1,
				}}
			>
				{t("bulkUploadDetail.bulkUploadSteps")}
			</Typography>
			<Divider sx={{ opacity: 0.5, marginBottom: 3 }} />
			<Box
				sx={{
					width: "100%",
					flex: 1,
					marginTop: 4,
					opacity: id > 0 && bulkUpload?.file ? 1 : 0.5,
				}}
			>
				<Stepper
					activeStep={currentStep}
					alternativeLabel={(size.width ?? 0) > 1200}
					orientation={(size.width ?? 0) > 1200 ? "horizontal" : "vertical"}
					sx={{
						"& .MuiStepLabel-vertical": {
							flexDirection: "column",
							alignItems: "flex-start",
							justifyContent: "center",
							"& .MuiStepLabel-label": {
								fontSize: 10,
							},
						},
						maxWidth: (size.width ?? 0) <= 1200 ? 150 : "100%",
						marginTop: 4,
					}}
				>
					{steps.map((step, index) => (
						<Step
							key={index}
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 1,
								alignItems: "center",
								opacity:
									!bulkUpload?.is_add_products ||
									(bulkUpload?.is_add_products && step.isAddProducts)
										? 1
										: 0.5,
							}}
						>
							<StepLabel>{step.title} </StepLabel>
							{step.action !== undefined && (
								<Button
									variant="outlined"
									onClick={() => {
										setSelectedStep(index);
										setShowTriggerModal(true);
									}}
									disabled={
										isLoadingMutateAction ||
										(bulkUpload?.is_add_products === true &&
											!step.isAddProducts)
									}
									sx={{ minHeight: 34 }}
								>
									{isLoadingMutateAction && selectedStep === index ? (
										<CircularProgress size={12} sx={{ color: "#34918E" }} />
									) : (
										t("bulkUploadDetail.trigger")
									)}
								</Button>
							)}
						</Step>
					))}
				</Stepper>
				<Box sx={{ marginTop: 4 }}>
					<span className={classes.inputLabel}>
						{t("bulkUploadDetail.stepDetails")}
					</span>
					<pre
						style={{
							background: "#24262b",
							borderRadius: "8px",
							width: "100%",
							textAlign: "left",
							maxHeight: "calc(100vh - 300px)",
							overflow: "auto",
							padding: "20px",
						}}
					>
						<code
							style={{
								fontFamily: "monospace",
								color: "#e2e2e9",
								whiteSpace: "break-spaces",
								display: "flex",
								flexDirection: "column",
								gap: 8,
							}}
						>
							{isLoadingMutateAction
								? t("bulkUploadDetail.loading")
								: bulkUpload?.details?.map((detail) => (
										<Box sx={{ lineHeight: "2" }}>
											<Chip
												label={detail.level}
												sx={{
													backgroundColor:
														detail.level === "error" ? "#eb4343" : "#43abeb",
													color: "#ffffff",
													paddingLeft: 1,
													paddingRight: 1,
													height: "26px",
													fontSize: "12px",
												}}
											/>{" "}
											{detail.message}
										</Box>
								  ))}
						</code>
					</pre>
				</Box>
			</Box>

			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={() => {
					setShowDeleteModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("companyDetail.confirmDeleteDocument")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowDeleteModal(false);
								}}
							>
								{t("companyDetail.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={true}
								onClick={() => {}}
							>
								{t("companyDetail.delete")}
							</Button>
						</Box>
					</Box>
				}
			/>

			<BasicModal
				width={600}
				showModal={showTriggerModal}
				handleClose={() => {
					setShowTriggerModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("bulkUploadDetail.confirmTriggerStep")}
						</Typography>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							<b>{selectedStep > -1 ? steps[selectedStep].title : ""}</b>
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowTriggerModal(false);
								}}
							>
								{t("bulkUploadDetail.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									if (steps[selectedStep].action !== undefined) {
										steps[selectedStep].action!();
									}
									setShowTriggerModal(false);
								}}
							>
								{t("bulkUploadDetail.trigger")}
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
