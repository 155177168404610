import React, { useState, useContext } from "react";
import productsTheme from "../../ProductsTheme";
import supplierTheme from "../../SupplierTheme";
import { DefaultTheme as Theme } from "@mui/system";
import { DisplayContext } from "../../contexts/display";
import { ThemeContextType, PropTypes } from "./ThemeContext.types";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";

export const ThemeContext = React.createContext<ThemeContextType>({
	theme: null,
	setTheme: (theme: Theme) => {},
});

export const ThemeProvider: React.FC<PropTypes> = ({ children }) => {
	const { displayMode } = useContext(DisplayContext);
	const [theme, setTheme] = useState<Theme>(
		displayMode === "SUPPLIER" ? supplierTheme : productsTheme
	);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			<MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
		</ThemeContext.Provider>
	);
};
