import { ICompany, IProductLCA } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { CompanyTypes } from "./company.types";

class Company {
    async create({ name, description, formatted_address, address, city, state, zip_code, phone, email, country, contact_name, contact_email, contact_title, historically_approved, total_estimations, historically_amazon_certified, seen_historically_approved_message, is_parent_company,
        legally_recognized_companies,
        government_entities,
        payor_companies,
        company_or_amazon_webpages,
        company_representative_name,
        company_representative_title,
        are_any_entities_deemed_hostile_to_usa,
        are_any_people_listed_in_ofac,
        are_any_people_engaged_in_money_laundering,
        are_any_people_subject_to_usa_trade_restrictions,
        total_first_year_forecasted_sales, is_supplier, is_supplier_parent_company }: CompanyTypes.Create): Promise<ICompany> {
        const response = await connectionsClient.client.post(is_parent_company ? "child-companies" : "companies", {
            name, description, formatted_address, address, city, state, zip_code, phone, email, country, contact_name, contact_email, contact_title, historically_approved, total_estimations, historically_amazon_certified, seen_historically_approved_message, legally_recognized_companies,
            government_entities,
            payor_companies,
            company_or_amazon_webpages,
            company_representative_name,
            company_representative_title,
            are_any_entities_deemed_hostile_to_usa,
            are_any_people_listed_in_ofac,
            are_any_people_engaged_in_money_laundering,
            are_any_people_subject_to_usa_trade_restrictions,
            total_first_year_forecasted_sales,
            is_supplier: is_supplier,
            is_parent_company: is_supplier_parent_company
        });
        return response.data;
    }

    async list(search?: string, page?: number, sort: string = "-updated_at", pageSize: number = 10, ids?: Array<number>, signal?: AbortSignal): Promise<{ count: number, results: Array<ICompany> }> {
        const endpoint = "/companies"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", page_size: pageSize, is_test_company: localStorage.getItem("showTestData") ? undefined : false, id__in: ids ? ids.join(",") : undefined },
            signal
        });
        return response.data;
    }

    async getOne(companyId: number): Promise<ICompany> {
        const endpoint = "/companies/" + companyId
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async update({ id, name, description, formatted_address, address, city, state, zip_code, phone, email, country, contact_name, historically_approved, total_estimations, requested_historically_approved, historically_amazon_certified, seen_historically_approved_message, legally_recognized_companies,
        government_entities,
        payor_companies,
        company_or_amazon_webpages,
        company_representative_name,
        company_representative_title,
        are_any_entities_deemed_hostile_to_usa,
        are_any_people_listed_in_ofac,
        are_any_people_engaged_in_money_laundering,
        are_any_people_subject_to_usa_trade_restrictions,
        total_first_year_forecasted_sales, attestation_form_approved }: CompanyTypes.Update): Promise<ICompany> {
        const endpoint = "/companies/" + id
        const response = await connectionsClient.client.patch(endpoint, {
            name, description, formatted_address, address, city, state, zip_code, phone, email, country, contact_name, historically_approved, total_estimations, requested_historically_approved, historically_amazon_certified, seen_historically_approved_message, legally_recognized_companies,
            government_entities,
            payor_companies,
            company_or_amazon_webpages,
            company_representative_name,
            company_representative_title,
            are_any_entities_deemed_hostile_to_usa,
            are_any_people_listed_in_ofac,
            are_any_people_engaged_in_money_laundering,
            are_any_people_subject_to_usa_trade_restrictions,
            total_first_year_forecasted_sales, attestation_form_approved
        });
        return response.data;
    }

    async delete(companyIds: number[]): Promise<void> {
        const deletePromises = companyIds.map((companyId) =>
            connectionsClient.client.delete(`/companies/${companyId}`)
        );
        await Promise.all(deletePromises);
    }

    async getLCADocuments(companyId: number, page?: number, sort: string = "-updated_at"): Promise<{ count: number, results: Array<IProductLCA> }> {
        const endpoint = "/companies/" + companyId + "/lca-documents/"
        const response = await connectionsClient.client.get(endpoint, {
            params: { ordering: sort, page: page?.toString() ?? "1", per_page: 999 }
        });
        return response.data;
    }

    async childrenList(search?: string, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<ICompany> }> {
        const endpoint = "/child-companies"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", page_size: pageSize },
            signal
        });
        return response.data;
    }

    async impersonate(child_company_id: number): Promise<boolean> {
        const endpoint = "/impersonate/"
        const response = await connectionsClient.client.post(endpoint, { child_company_id });

        connectionsClient.updateTokens({
            access: response.data.access,
            refresh: response.data.refresh,
            userId: null,
            parentAccess: connectionsClient.token.access,
            parentRefresh: connectionsClient.token.refresh,
            parentUserId: connectionsClient.token.userId
        });

        return true;
    }
}

export const company = new Company();